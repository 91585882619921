import React from 'react';
import '../theme/pages/splashScreen.css';


class LoadingInside extends React.Component {
	render() {
		return (
			<div className="splash-screen">
				<div className="loading-dot">.</div>
				<div className="lds-dual-ring"></div>
				<div className="lds-dual-ring-reverse"></div>
			</div>
		);
	}
};

export default LoadingInside;
