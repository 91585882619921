import { action } from "mobx";
import Axios from "axios";
import ENV from "../env/env";

export default class AppRegistryStore {
	private _userRegistrationUrl = "";
	private _passwordResetUrl = "";
	private _programStartDate: Date | null= null;
	private _programEndDate: Date | null = null;
	private _googleAnalyticsTrackingNumber = "";
	private _bnsMobileApiUrl = "";
	private _bannerUrl: string = "";
	private _version: string = "8.0";

	public get userRegistrationUrl() {
		return this._userRegistrationUrl;
	}

	public get passwordResetUrl() {
		return this._passwordResetUrl;
	}

	public get mobileApiUrl() {
		return ENV.REACT_APP_API_BASE_URL;
	}

	public get programStartDate() {
		return this._programStartDate;
	}

	public get programEndDate() {
		return this._programEndDate;
	}
	public get googleAnalyticsTrackingNumber() {
		return this._googleAnalyticsTrackingNumber;
	}

	public get bnsMobileApiUrl() {
		return this._bnsMobileApiUrl;
	}
	public get bannerUrl() {
		return this._bannerUrl;
	}
	
	public get version() {
		return this._version;
	}

	@action async init() {
		try {
			const url = this.mobileApiUrl + "/v2/LatestVersionInfo";
			const res = await Axios.get<{
				Version: string;
				ProgramStartDate: string | null;
				ProgramEndDate: string | null;
			}>(url);
			const versionData = res.data;

			this._programStartDate = versionData.ProgramStartDate === null ? null : new Date(versionData.ProgramStartDate);
			this._programEndDate = versionData.ProgramEndDate === null ? null : new Date(versionData.ProgramEndDate);
			this._version = versionData.Version;

			return true;
		} catch (err) {
			return false;
		}
	}
}