import { IonItem, IonLabel, IonText, IonIcon, } from '@ionic/react';
import React from 'react';
import RankingModel from '../models/RankingModel';
import { PATH_RANKING } from '../common/Constants';
import { arrowForward } from 'ionicons/icons';

interface Props {
	ranking: RankingModel
}

class RankingListItem extends React.Component<Props> {

	render() {
		return (
			<IonItem detail={false} lines="none" className="item-ranking-list custom-width" routerLink={PATH_RANKING + "/" + this.props.ranking.rank}>
				<IonLabel>
					<IonText className="name">{this.props.ranking.name}</IonText><br/> 
					<IonText className="rank">Rank: {this.props.ranking.rank}</IonText>
				</IonLabel>
				<IonIcon icon={arrowForward}/>
			</IonItem>
		);
	}
};

export default RankingListItem;