

export function isStringANumber(str: string) {
    return /^\d+$/.test(str);
}
export function getformatNumber(x: number, decimals: number) {
    let fn = "";
    if (x || x === 0){
        var parts = x.toFixed(decimals).split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        fn = parts.join('.');
    }
    return fn;
}

export function getformatDate(dateString: string) {
    // console.log('date', dateString);
    const date = new Date(dateString);
    // console.log('date', date);
    // console.log('date', date.getMonth() + '/' + date.getDay()  + '/' + date.getFullYear());
    // const date = Moment(dateString).format();
    return date.getMonth()+1 + '/' + date.getDate()  + '/' + date.getFullYear();
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}