import { IonContent, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, withIonLifeCycle, IonImg, IonButtons, IonIcon, IonRouterLink, IonFooter, IonToast } from '@ionic/react';
import React from 'react';
import { Plugins, PluginListenerHandle } from '@capacitor/core';
import ENV from '../env/env';
import AuthenticationStore from '../mobx/AuthenticationStore';
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../components/Loading';
import Copyright from '../components/Copyright';
import LoginResult from '../models/LoginResult';
/* Custom CSS */
import '../theme/pages/login.css';
import { arrowBack } from 'ionicons/icons';
import { PATH_REGISTRATION, PATH_RESET_PASSWORD, PATH_HOME } from '../common/Constants';
import AppStore from '../mobx/AppStore';
import AppRegistryStore from '../mobx/AppRegistryStore';
import { eye, eyeOff } from 'ionicons/icons';

const { App } = Plugins;

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	appRegistryStore: AppRegistryStore;
	appStore: AppStore;
}

type State = {
	isLoading: boolean,
	hiddenPass: boolean,
	username: string,
	password: string,
	errorMessage: string | null ,
	showToast: boolean,
	toastMsg: string,
	signInScreen: string

}

type Member = {
	isMounted: boolean,
	backButtonHandle: PluginListenerHandle | null
}

class Login extends React.Component<Props, State> {

	state: State = {
		isLoading: true,
		hiddenPass: true,
		username: "",
		password: "",
		errorMessage: null,
		showToast: false,
		toastMsg: "",
		signInScreen: "days"// set custom names, more screens, "days", "signin"
	}

	member: Member = {
		isMounted: false,
		backButtonHandle: null
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		this.member.backButtonHandle = App.addListener("backButton", () => {
			App.exitApp();
		});

		this.props.authenticationStore.isValid()
			.then((isValid: boolean) => {
				if (this.member.isMounted) {
					if (isValid) {
						this.setState({ isLoading: false, username: "", password: "" });
						this.props.history.replace(PATH_HOME);
					} else {
						this.setState({ isLoading: false, username: "", password: ""});
					}
				}
			})
			.catch(() => {
				this.setState({ isLoading: false });
			});
	}

	ionViewDidLeave() {
		this.member.isMounted = false;
		this.member.backButtonHandle?.remove();
	}

	login = () => {
		this.setState({ isLoading: true });
		const authentication = {
			// version: ENV.c.version,
			version: this.props.appRegistryStore.version,
			email: this.state.username,
			password: this.state.password
		};
		this.props.authenticationStore.login(authentication)
			.then((loginResult: LoginResult) => {
				if (this.member.isMounted) {
					this.setState({ isLoading: false });
					if (loginResult.isSuccessful) {
						this.props.history.push(PATH_HOME);
					} else {
						this.setShowToast(true, 'The username or password is incorrect');
						this.setState({password : '' });
						// if (loginResult.message !== undefined) {
						// }
					}
				}
			})
			.catch(() => {
				this.setShowToast(true, 'The username or password is incorrect');
				this.setState({ isLoading: false, password : '' });
			});
	}

	toggleShowPass = () => {
		this.setState({ hiddenPass: !this.state.hiddenPass });
	}

	setShowToast = (change: boolean, msg: string) => {
		this.setState({ showToast: change, toastMsg: msg });
	}
	changeScreen = (name: string) => {
		this.setState({signInScreen: name});
	}

	dateDiffrence = () => {
        let oneDay = 24 * 60 * 60 * 1000,
			now = new Date();
		// console.log('dateDiffrence 1', now, oneDay);
		// console.log('dateDiffrence 2', this.props.appRegistryStore.programStartDate);
		// console.log('dateDiffrence 3', this.props.appRegistryStore.programStartDate!=null && this.props.appRegistryStore.programStartDate > now);

		
		
		// if(this.props.appRegistryStore.programStartDate!=null && this.props.appRegistryStore.programStartDate>now) {
		// 	console.log('dateDiffrence 4', now.getTime());
		// 	console.log('dateDiffrence 41', (now.getTime() - this.props.appRegistryStore.programStartDate?.getTime())/(oneDay));
		// 	console.log('dateDiffrence 42', Math.abs((now.getTime() - this.props.appRegistryStore.programStartDate?.getTime())/(oneDay)));
		// 	console.log('dateDiffrence 43', Math.ceil(Math.abs((now.getTime() - this.props.appRegistryStore.programStartDate?.getTime())/(oneDay))));
		// }

		if(this.props.appRegistryStore.programStartDate!=null && this.props.appRegistryStore.programStartDate>now)
			return Math.ceil(Math.abs((now.getTime() - this.props.appRegistryStore.programStartDate?.getTime())/(oneDay)));
		if(this.props.appRegistryStore.programEndDate!=null) {
			if(this.props.appRegistryStore.programEndDate < now)
				return 0;
			return Math.ceil(Math.abs((now.getTime() - this.props.appRegistryStore.programEndDate?.getTime())/(oneDay)));

		}
		return '';
		// return this.props.appRegistryStore.programStartDate?.toDateString();
	}
	isPastProgramDate = () =>{
		const now = new Date();
		if(this.props.appRegistryStore.programEndDate!=null) {
			return this.props.appRegistryStore.programEndDate < now;
		}
		return false;
	}

	render() {
		return (
			<IonPage className="login"> 
				<IonToolbar className="signin-toolbar"> 
					{this.state.signInScreen === "signin" ? 
						<>  <IonTitle className="signin-title" >Sign in</IonTitle>
							<IonButtons slot="start">
								<IonButton onClick={ () => this.changeScreen("days") } ><IonIcon icon={arrowBack} /></IonButton>
								{/*isPlatform("android" || "ios") && <IonButton ><IonIcon icon={arrowBack} /></IonButton>*/}
							</IonButtons> 
						</> : null }
				</IonToolbar>
				<IonContent className="ion-padding">
					{/* <IonLabel>{ENV.c.warning}</IonLabel> */}

					<IonItem lines="none">
						<IonImg className="gftg-logo" src={"./assets/r3-icons/G4G_Logo_FINAL_White.svg"} />
					</IonItem>

					{this.state.signInScreen === "days" ? 
					<div>
						{ this.isPastProgramDate() ? 
							<IonLabel className="program"> The program is closed at this time.</IonLabel>
						: 
						<>
							<IonLabel className="days-count">{ this.dateDiffrence() }</IonLabel>
							<IonLabel className="days-text"> REMAINING DAYS </IonLabel>
							<IonButton className="btn-login" onClick={ () => this.changeScreen("signin") } > Sign In</IonButton>
							<IonLabel className="or-text"> OR </IonLabel>
							<IonButton className="btn-register" routerLink={PATH_REGISTRATION}> Register </IonButton>
						</>
						}
					</div> : null }
					{this.state.signInScreen === "signin" ? 
					<div>
						<IonLabel className="welcome-title"> WELCOME </IonLabel><br />
						<IonLabel className="get-started"> To get started you need to sign in to our app. </IonLabel>
						<IonItem className="login-inputs">
							{/* <IonLabel className="lbl-color" position="floating">Username</IonLabel> */}
							<IonInput value={this.state.username} onIonChange={(e) => { this.setState({ username: (e.target as HTMLInputElement).value }); }}></IonInput>
						</IonItem>
						<IonItem className="login-inputs" >
							{/* <IonLabel className="lbl-color" position="floating">Password</IonLabel> */}
							<IonInput type={this.state.hiddenPass ? "password" : "text"} value={this.state.password} onIonChange={(e) => { this.setState({ password: (e.target as HTMLInputElement).value }); }}></IonInput>
							<IonIcon icon={this.state.hiddenPass ? eye : eyeOff} onClick={this.toggleShowPass}></IonIcon>
						</IonItem>
						<IonButton className="btn-login" onClick={this.login}>Sign In</IonButton>
						<IonItem lines="none" className="forgot-text">
							<IonLabel>Forgot Password? <IonRouterLink routerLink={PATH_RESET_PASSWORD}>Reset here.</IonRouterLink></IonLabel>
						</IonItem>
					</div> : null }
				</IonContent>
				<IonFooter className="ion-no-border footer-copyright">
					<IonToolbar className="footer-copyright-tool">
						<Copyright />
					</IonToolbar>
				</IonFooter>
				{this.state.isLoading && <Loading />}
				<IonToast
						isOpen={this.state.showToast}
						onDidDismiss={() => this.setShowToast(false, "")}
						message={this.state.toastMsg}
						position="top"
						color="danger"
						cssClass="toast-font-size"
						duration={2000}
						showCloseButton={false}
					/>
			</IonPage>
		);
	}
}

export default withIonLifeCycle(Login);
