import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import "../theme/pages/gauge.css";

interface Props {
	fillValue: number, // 0-1
	fillColor: string,
	backgroundColor: string
}

class Gauge extends React.Component<Props> {

	render() {
		const width = 250;
		const data = [
			{
				value: this.props.fillValue,
				color: this.props.fillColor
			},
			{
				value: 1 - this.props.fillValue,
				color: this.props.backgroundColor
			}
		];

		return (
			<div className="gauge">
				<div className="shadow-container">
					<div className="shadow"></div>
				</div>
				<PieChart className="pie-chart" width={width} height={width}>
					<Pie
						data={data}
						innerRadius={60}
						outerRadius={width / 2}
						startAngle={-315}
						endAngle={45}
						dataKey={"value"}
					>
						{data.map((_, i) => <Cell key={`cell-${i}`} fill={data[i].color} stroke={"#00000000"} />)}
					</Pie>
					<div>aaaaa</div>
				</PieChart>
			</div>
		);
	}
}

export default Gauge;
