import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, isPlatform, IonButton, IonIcon } from '@ionic/react';
import React from 'react';

class NoMatch extends React.Component<any, any > {

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonTitle>404 - Not Found</IonTitle>
						<IonButtons slot="start">
							{isPlatform("android" || "ios") && <IonButton onClick={ () => { this.props.history.goBack(); }}><IonIcon name="arrow-back" >.</IonIcon></IonButton>}
						</IonButtons>
					</IonToolbar>
				</IonHeader>
			</IonPage>
		);
	}
};

export default NoMatch;