import { loadEnvs } from "load-envs"

interface Constants {
	warning: string,
}

export enum EnvTypes {
	Production = "production",
	Staging = "staging",
	Development = "development",
}

interface Environment {
	c: Constants;

	NODE_ENV: EnvTypes;
	REACT_APP_API_BASE_URL: string;
	REACT_APP_IMAGE_URL: string;
	REACT_APP_SITE_CORE_URL: string;
}

function getEnv(): Environment {
	const ENV: Environment = loadEnvs([
		{
			envKey: "NODE_ENV",
			options: {
				validValues: Object.values(EnvTypes),
			},
		},
		{
			envKey: "REACT_APP_API_BASE_URL",
		},
		{
			envKey: "REACT_APP_IMAGE_URL",
		},
		{
			envKey: "REACT_APP_SITE_CORE_URL",
		}
	]);

	return ENV;
}

const ENV: Environment = getEnv();
export default ENV;
