import { IonItem, IonImg, IonIcon, IonRow, IonCol, IonGrid } from '@ionic/react';
import React from 'react';
import ProductModel from '../models/ProductModel';
import { PATH_PRODUCT_DETAIL } from '../common/Constants';
import { arrowForward } from 'ionicons/icons';

interface Props {
	product: ProductModel;
}

class ProductItem extends React.Component<Props> {
	render() {
		return (
			<IonItem detail={false} lines="none" className="product-card" routerLink={PATH_PRODUCT_DETAIL + "/" + this.props.product.id}>
				
				<IonGrid className="product-grid contents">
					<IonRow className="contents">
						<IonCol size="3.5">
							<IonImg  src={ this.props.product.imageBytes !== "" ? "data:image/jpeg;base64," + this.props.product.imageBytes : "./assets/img/product-no-img.PNG"}  />
						</IonCol>
						<IonCol>
						{this.props.product.isFeatured &&
							<p className="product-featured" >
								<IonIcon src='./assets/icons/ios-star.svg' />
								<b>&nbsp;Featured</b>
							</p>}
							<p className="product-category">{this.props.product.categoryName} - {this.props.product.number}</p>
					
							<p className="product-name">{this.props.product.name}</p>
					
							<p className="product-points">{this.props.product.points} points</p>
						</IonCol>
						<IonCol size="1">
							<IonIcon className="card-forward" icon={arrowForward.ios} />
						</IonCol>
					</IonRow>
				</IonGrid>
				
			</IonItem>
		);
	}
};

export default ProductItem;
