import React from "react";
//@ts-ignore
import { Bar, Chart } from "react-chartjs-2";
import { IonText } from "@ionic/react";
import "chartjs-plugin-annotation";
import { getformatNumber } from "../common/utils";
import { ChartOptions } from "chart.js";

export interface BarConfigData {
	barNumber: number;
	name: string;
	color: string;
	prefix?: string;
	suffix?: string;
}

export interface BarSalesData {
	value: number;
	projected: number;
	goals: number[];
	goalPercent: number;
}

interface Props {
	barData: BarConfigData;
	barSalesData: BarSalesData;
	isClickable: boolean;
	setSelectedBarData: (barData: BarConfigData) => void;
	hasNoData: boolean;
}

class BarElement extends React.Component<Props> {
	onClick = () => {
		if (!this.props.isClickable) return;
		this.props.setSelectedBarData(this.props.barData);
	};

	checkUpdate = (e: any) => {
		e.data.datasets[0].data[0] = 0;

		setTimeout(() => {
			e.data.datasets[0].data[0] = this.props.barSalesData.value;
			e.update({
				duration: 2000,
				easing: "easeOutQuart",
			});
		}, 100);
	};

	componentDidMount() {
		Chart.pluginService.register({
			afterDraw: () => {
				//@ts-ignore
				if (this.refs.chart) {
					//@ts-ignore
					let ctx = this.refs.chart.chartInstance.chart.ctx;
					ctx.font = "14px Verdana";
					ctx.fillStyle = "#ffffff";
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					const prefix = this.props.barData.prefix ?? "";
					const suffix = this.props.barData.suffix ?? "";
					//@ts-ignore
					this.refs.chart.chartInstance.chart.config.data.datasets.forEach(function (dataset) {
						const dataArray = dataset.data;
						dataset._meta[Object.keys(dataset._meta)[0]].data.forEach(function (bar) {
							ctx.fillText(
								prefix + getformatNumber(dataArray[0], 0) + suffix,
								bar._view.x,
								bar._view.y,
							);
						});
					});
				}
			},
		});
	}

	formatValueLabel(val: number) {
		// ex: "$4100" ; "4100" ; "4100%"
		const hasNoData = this.props.hasNoData;
		const prefix = this.props.barData.prefix ?? "";
		const suffix = this.props.barData.suffix ?? "";
		if (hasNoData) {
			return "";
		} else {
			return prefix + getformatNumber(val, 0) + suffix;
		}
	}

	getGoals() {
		return this.props.barSalesData.goals.map((goal) => {
			return {
				type: "line",
				mode: "horizontal",
				scaleID: "y-axis-0",
				value: goal,
				borderColor: "#fff",
				borderWidth: 2,
				label: {
					enabled: true,
					backgroundColor: "transparent",
					position: "left",
					fontColor: "#fff",
					yAdjust: 12,
					content: this.formatValueLabel(goal),
				},
			};
		});
	}

	render() {
		var barChartData = {
			labels: [this.props.barData.name],
			datasets: [
				{
					data: [this.props.barSalesData.value],
					backgroundColor: [this.props.barData.color],
					categoryPercentage: 0.8,
					minBarLength: 2,
				},
			],
		};

		var barChartOptions = {
			animation: {
				duration: 3000,
			},
			annotation: {
				annotations: this.getGoals(),
			},
			responsive: true,
			layout: {
				padding: {
					top: 25,
					left: 15,
					right: 15,
					bottom: 15,
				},
			},
			responsiveAnimationDuration: 0,
			maintainAspectRatio: false,
			onResize: this.checkUpdate,
			onClick: this.onClick,
			legend: {
				display: false,
			},
			tooltips: {
				enabled: false,
			},
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							max:
								parseInt(this.props.barSalesData.goals.toString()) >
								parseInt(this.props.barSalesData.value.toString())
									? parseInt(this.props.barSalesData.goals.toString())
									: parseInt(this.props.barSalesData.value.toString()) +
									  parseInt(this.props.barSalesData.value.toString()) * 0.02,
							labelOffset: 8,
							padding: -5,
							maxTicksLimit: 2,
							autoSkip: false,
							mirror: true,
							callback: function (value: any, index: any, values: any) {
								if (value === 0) return 0;
								return "";
							},
							fontColor: "rgba(255, 255, 255, 1)",
						},
						gridLines: {
							offsetGridLines: true,
							zeroLineWidth: 1.5,
							display: false,
							drawBorder: false, //does not change anything
							drawTicks: false,
							tickMarkLength: 10,
							color: "rgba(255, 255, 255, 0.5)",
							zeroLineColor: "rgba(255, 255, 255, 1)",
						},
					},
				],
				xAxes: [
					{
						//stacked: true,
						ticks: {
							padding: 20,
							backdropColor: "rgba(255, 255, 255, 1)",
							beginAtZero: true,
							fontColor: "rgba(255, 255, 255, 0)",
						},
						gridLines: {
							zeroLineWidth: 1.5,
							zeroLineColor: "rgba(255, 255, 255, 1)",
							display: true,
							drawTicks: false,
							tickMarkLength: 10,
							color: "rgba(255, 255, 255, 1)",
							lineWidth: 0,
						},
					},
				],
			},
		} as ChartOptions;

		return (
			<div className="bar-component">
				{this.props.hasNoData && (
					<IonText class="fix-to-chart">
						Progress will be displayed upon receipt of your first order
					</IonText>
				)}
				<div className="bar-container" id="cont">
					<Bar ref={"chart"} data={barChartData} options={barChartOptions} />
				</div>
				<IonText className="bar-name">{this.props.barData.name}</IonText>
			</div>
		);
	}
}

export default BarElement;
