import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonContent,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	withIonLifeCycle,
	IonImg,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import AuthenticationStore from "../mobx/AuthenticationStore";
import Loading from "../components/Loading";
import RankingModel from "../models/RankingModel";
import { arrowBack } from "ionicons/icons";
import { PATH_LOGIN, PATH_RANKING } from "../common/Constants";
import RankingListStore from "../mobx/RankingListStore";
import { isStringANumber } from "../common/utils";
import "../theme/pages/ranking.css";
import AppStore from "../mobx/AppStore";

type MatchParams = {
	rank: string;
};

interface Props extends RouteComponentProps<MatchParams> {
	authenticationStore: AuthenticationStore;
	rankingListStore: RankingListStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean;
	isLoading: boolean;
	ranking: RankingModel | undefined;
};

type Member = {
	isMounted: boolean;
};

class MyRanking extends React.Component<Props, State> {
	state: State = {
		isHidden: true,
		isLoading: true,
		ranking: undefined,
	};

	member: Member = {
		isMounted: false,
	};

	ionViewDidEnter() {
		this.member.isMounted = true;
		if (isStringANumber(this.props.match.params.rank)) {
			this.props.authenticationStore
				.isValid()
				.then((isValid) => {
					if (this.member.isMounted) {
						if (isValid) {
							this.setState({ isHidden: false });

							if (this.props.rankingListStore.rankingList.length !== 0) {
								const ranking = this.props.rankingListStore.getRanking(
									parseInt(this.props.match.params.rank),
								);
								this.setState({ ranking: ranking, isLoading: false });
							} else {
								this.props.rankingListStore
									.fetch()
									.then(() => {
										const ranking = this.props.rankingListStore.getRanking(
											parseInt(this.props.match.params.rank),
										);
										return new Promise((res) => {
											this.setState({ ranking: ranking }, () => res("resolve after set state"));
										});
									})
									.finally(() => {
										this.setState({ isLoading: false });
									});
							}
						} else {
							this.props.history.replace(PATH_LOGIN);
						}
					}
				})
				.catch(() => {
					this.props.history.replace(PATH_LOGIN);
				});
		} else {
			this.props.history.replace(PATH_RANKING);
		}
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	getformatNumber = (x: number, decimals: number) => {
		let fn = "";
		if (x || x === 0) {
			var parts = x.toFixed(decimals).split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			fn = parts.join(".");
		}
		return fn;
	};

	render() {
		return (
			<IonPage className="ranking">
				{!this.props.appStore.isWeb ? (
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<IonButtons slot="start">
									<IonButton
										onClick={() => {
											this.props.history.goBack();
										}}
									>
										<IonIcon icon={arrowBack} />
									</IonButton>
								</IonButtons>
								<IonTitle className="toolbar-title">Ranking</IonTitle>
							</IonToolbar>
							<div className="curve-static"></div>
						</IonHeader>
					</>
				) : (
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<>
									<IonTitle className="toolbar-title">Ranking</IonTitle>
								</>
							</IonToolbar>
						</IonHeader>
					</>
				)}
				{this.state.isHidden ? null : (
					<>
						<IonContent>
							<IonGrid className="ranking-grid custom-width">
								<IonRow>
									<IonCol className="ion-text-center">
										<IonLabel className="values">{this.state.ranking?.name}</IonLabel>
									</IonCol>
								</IonRow>
								<IonRow className="medal-container">
									<IonImg className="medal" src=".\assets\img\Medal.png"></IonImg>
									<div>
										<IonLabel className="values position">{this.state.ranking?.rank}</IonLabel>
										<IonLabel className="titles posotion">Current Position</IonLabel>
									</div>
								</IonRow>
								<div className="ranking-fields">
									{this.state.ranking !== undefined &&
										this.props.rankingListStore.rankingListHeaders.map((rankingHeader) => {
											console.log(rankingHeader);
											return (
												<div className="ion-text-center field">
													<div className="values">{this.state.ranking && this.state.ranking[rankingHeader]}</div>
													<div className="titles">{rankingHeader}</div>
												</div>
											);
										})}
								</div>
							</IonGrid>
						</IonContent>
					</>
				)}
				{this.state.isLoading && <Loading />}
			</IonPage>
		);
	}
}

export default withIonLifeCycle(MyRanking);
