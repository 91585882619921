import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonItem, IonButton, IonIcon, withIonLifeCycle, IonList} from '@ionic/react';
import React from 'react';
import VendorItem from '../components/VendorItem';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import VendorsStore from '../mobx/VendorsStore';
import VendorModel from '../models/VendorModel';
import { arrowBack } from 'ionicons/icons';
import { PATH_LOGIN } from '../common/Constants';
import '../theme/pages/featuredVendors.css';
import AppStore from '../mobx/AppStore';

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	vendorsStore: VendorsStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean
}

type Member = {
	isMounted: boolean
}

class FeaturedVendors extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true
	}

	member: Member = {
		isMounted: false
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		this.props.authenticationStore.isValid()
		.then((isValid) => {
			if (this.member.isMounted) {
				if (isValid) {
					this.setState({isHidden: false});
					this.props.vendorsStore.fetch(720)
						.finally(() => {
							this.setState({isLoading: false});
						});
				} else {
					this.props.history.replace(PATH_LOGIN);
				}
			}
		})
		.catch(() => {
			this.props.history.replace(PATH_LOGIN);
		});
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	render() {
		return (
			<IonPage className="featured-vendors">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={ () => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								<IonTitle className="toolbar-title" >Featured Vendors</IonTitle>
							</IonToolbar>
							<div className="curve-static"></div>
						</IonHeader>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='header-toolbar'>
								<>
									<IonTitle  className="toolbar-title" >Featured Vendors</IonTitle>
								</> 
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
				<>
				<IonContent className="content-main">
					<IonItem lines="none" className="gold">Gold Sponsors</IonItem>
					<IonList className="vendor-list">
					{this.props.vendorsStore.vendors.map((it: VendorModel) => {
						if (it.vendorLevelId === 3) //
							return <VendorItem key={it.id} vendor={it}></VendorItem>;
						else return null;
					})}
					</IonList>
					<IonItem lines="none" className="silver">Silver Sponsors</IonItem>
					<IonList className="vendor-list">
					{this.props.vendorsStore.vendors.map((it: VendorModel) => {
						if (it.vendorLevelId === 2 )
							return <VendorItem key={it.id} vendor={it}></VendorItem>;
						else return null;
					})}
					</IonList>
					<IonItem lines="none" className="bronze">Bronze Sponsors</IonItem>
					<IonList className="vendor-list">
					{this.props.vendorsStore.vendors.map((it: VendorModel) => {
						if (it.vendorLevelId === 1)
							return <VendorItem key={it.id} vendor={it}></VendorItem>;
						else return null;
					})}
					</IonList>
				</IonContent>
				</>}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(FeaturedVendors);