import { IonItem, IonImg, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import VendorModel from '../models/VendorModel';
import { PATH_PRODUCTS } from '../common/Constants';
import { arrowForward } from 'ionicons/icons';

interface Props {
    vendor: VendorModel
}

class VendorItem extends React.Component<Props> {
    render() {
        return (
            <IonItem detail={false} lines="none" className="vendor-card" routerLink={PATH_PRODUCTS + "?vendorId=" + this.props.vendor.id}>
                <IonGrid className="contents">
                    <IonRow className="contents">
                        <IonCol size="3.5">
                            <IonImg src={this.props.vendor.imageBytes !== "" ? "data:image/jpeg;base64," + this.props.vendor.imageBytes : "./assets/img/product-no-img.PNG"} />
                        </IonCol>
                        <IonCol>
                            <p>{this.props.vendor.name}</p>
                        </IonCol>
                        <IonCol size="1">
                            <IonIcon className="card-forward" icon={arrowForward} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonItem>
        );
    }
};

export default VendorItem;