import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from './pages/Login';
import Registration from './pages/Registration';
import ResetPassword from './pages/ResetPassword';
import Home from './pages/Home';
import About from './pages/About';
import MySalesRep from './pages/MySalesRep';
import TOU from './pages/TOU';
import PP from './pages/PP';
import Products from './pages/Products';
import Promotions from './pages/Promotions';
import Inbox from './pages/Inbox';
import Customers from './pages/Customers';
import CurrentStatus from './pages/CurrentStatus';
import FeaturedVendors from './pages/FeaturedVendors';
import Ranking from './pages/Ranking';
import UserAccount from './pages/UserAccount';
import ProductDetail from './pages/ProductDetail';
import Message from './pages/Message';
import RankingList from './pages/RankingList';
import NoMatch from './pages/NoMatch';
import { Plugins } from '@capacitor/core';
import NetworkCheck from './components/NetworkCheck';
import AuthenticationStore from './mobx/AuthenticationStore';
import ProductsStore from './mobx/ProductsStore';
import ProductStore from './mobx/ProductStore';
import PromotionsStore from './mobx/PromotionsStore';
import MessageStore from './mobx/MessageStore';
import CustomersStore from './mobx/CustomersStore';
import SalesDataStore from './mobx/SalesDataStore';
import VendorsStore from './mobx/VendorsStore';
import RegistrationStore from './mobx/RegistrationStore';
import RankingListStore from './mobx/RankingListStore';
import SplashScreen from './pages/SplashScreen';
import OtherContentStore from './mobx/OtherContentStore';
import MySalesRepStore from './mobx/MySalesRepStore';
// import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { GOOGLE_TRACKING_ID } from "./common/Constants";
import ReactGA from 'react-ga';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom CSS */
import './theme/style.css';
import { PATH_PRODUCT_DETAIL, PATH_PP, PATH_TOU, PATH_LOGIN, PATH_REGISTRATION, PATH_RESET_PASSWORD, PATH_HOME, PATH_PRODUCTS, PATH_PROMOTION_PRODUCTS, PATH_PROMOTIONS, PATH_INBOX, PATH_INBOX_MAIL, PATH_CUSTOMERS, PATH_CURRENT_STATUS, PATH_FEATURED_VENDORS, PATH_RANKING, PATH_USER_ACCOUNT, PATH_ABOUT, PATH_MY_SALES_REP, PATH_404 } from './common/Constants';
import AppRegistryStore from './mobx/AppRegistryStore';
import PushNotificationStore from './mobx/PushNotificationStore';
import SideMenu from './components/SideMenu';
import { observer } from 'mobx-react';
import AppStore from './mobx/AppStore';

const appRegistryStore = new AppRegistryStore();
// const ga = new GoogleAnalytics();
const authenticationStore = new AuthenticationStore(appRegistryStore);
const productsStore = new ProductsStore(appRegistryStore, authenticationStore);
const productStore = new ProductStore(appRegistryStore, authenticationStore);
const promotionsStore = new PromotionsStore(appRegistryStore, authenticationStore);
const messageStore = new MessageStore(appRegistryStore, authenticationStore);
const customersStore = new CustomersStore(appRegistryStore, authenticationStore);
const salesDataStore = new SalesDataStore(appRegistryStore, authenticationStore);
const vendorsStore = new VendorsStore(appRegistryStore, authenticationStore);
const registrationStore = new RegistrationStore(appRegistryStore);
const rankingListStore = new RankingListStore(appRegistryStore, authenticationStore);
const otherContentStore = new OtherContentStore(appRegistryStore, authenticationStore);
const mySaleRepStore = new MySalesRepStore(appRegistryStore, authenticationStore);
const pushNotificationStore = new PushNotificationStore(appRegistryStore, authenticationStore, messageStore);
const appStore = new AppStore();

const { Network } = Plugins;

type State = {
	isSplashing: boolean,
	isConnected: boolean,
	isValid: boolean,
	isWeb: boolean,
}

window.addEventListener("resize", () => appStore.onWindowResize());

let routeHistory = ''; // Used to keep track of routing to only trigger GA when it changes

@observer
export class App extends React.Component {

	state: State = {
		isSplashing: true,
		isConnected: false,
		isValid: false,
		isWeb: false,
	}

    // @computed get isLoggedIn2() {
    //     return ;
	// }
	componentWillMount() {
		const updateConnectionStatus = async () => {
			const status = await Network.getStatus();
			this.setState({ isConnected: status.connected })
		}

		updateConnectionStatus();

		Network.addListener('networkStatusChange', (status) => {
			console.log("Network status changed", status);
			if (this.state.isConnected !== status.connected) {
				this.setState({ isConnected: status.connected })
			}
		});

		// const root = document.documentElement;
		// root.style.setProperty('--ion-color-primary', '#ff0000');
		// Promise.all([
		// 	appRegistryStore.fetch(),
		// 	new Promise((res) => {
		// 		setTimeout(() => {
		// 			this.setState({ isSplashing: false });
		// 			this.setState({ isValid: authenticationStore.isLoggedIn });
		// 			ReactGA.initialize(appRegistryStore.googleAnalyticsTrackingNumber || GOOGLE_TRACKING_ID);
		// 		}, 1000);
		// 	})
		// ])
		(async () => {
			await appRegistryStore.init();
			this.setState({ isSplashing: false });
			this.setState({ isValid: authenticationStore.isLoggedIn });
			ReactGA.initialize(appRegistryStore.googleAnalyticsTrackingNumber || GOOGLE_TRACKING_ID);
		})();
		
		// Promise.all([
		// 	appRegistryStore.fetch(),
			
		// ]).then(()=>{			
		// 	appRegistryStore.fetch2()
		// })
	}

	componentDidMount() {
		appStore.onWindowResize();
	}

	render() {
		return (
			<IonApp>
				{this.state.isSplashing ? <SplashScreen /> :
					<>
						{!this.state.isConnected && <NetworkCheck />}
						<IonReactRouter>
							{ authenticationStore.isLoggedIn ?
								<>{ appStore.isWeb && <Route render={(props) => <SideMenu {...props} 
								authenticationStore={authenticationStore}
								messageStore={messageStore}
								appStore={appStore} />} /> }</>
								:
								<>
								</>
							}
							<IonRouterOutlet mode="md" class={`${appStore.isWeb ? "web " : ""}`+`${authenticationStore.isLoggedIn ? "" : " notLoggedIn"}`}>
								<Redirect exact path="/" to={PATH_LOGIN} />
								<Route path={PATH_LOGIN} render={
									(props) => <Login 
										{...props} 
										authenticationStore={authenticationStore}
										appRegistryStore={appRegistryStore}
										appStore={appStore} />
								} />
								<Route path={PATH_REGISTRATION} render={
									(props) => <Registration
										{...props} 
										authenticationStore={authenticationStore}
										registrationStore={registrationStore}
										otherContentStore={otherContentStore}
										appStore={appStore} />
								} />
								<Route path={PATH_RESET_PASSWORD} render={
									(props) => <ResetPassword 
										{...props} 
										authenticationStore={authenticationStore}
										appStore={appStore} />
								} />
								<Route path={PATH_HOME} render={
									(props) => <Home
										{...props}
										authenticationStore={authenticationStore}
										pushNotificationStore={pushNotificationStore}
										salesDataStore={salesDataStore} 
										messageStore={messageStore}
										appStore={appStore} />
								} />
								<Route exact path={PATH_PRODUCTS} render={
									(props) => <Products
										{...props}
										authenticationStore={authenticationStore}
										productsStore={productsStore}
										appStore={appStore} />
								} />
								<Route exact path={PATH_PROMOTION_PRODUCTS} render={
									(props) => <Products
										{...props}
										authenticationStore={authenticationStore}
										productsStore={productsStore}
										appStore={appStore} />
								} />
								<Route path={PATH_PRODUCT_DETAIL + "/:id"} render={
									(props) => <ProductDetail
										{...props}
										authenticationStore={authenticationStore}
										productStore={productStore}
										parentPath={PATH_PRODUCTS}
										appStore={appStore} />
								} />
								<Route exact path={PATH_PROMOTIONS} render={
									(props) => <Promotions
										{...props}
										authenticationStore={authenticationStore}
										promotionsStore={promotionsStore}
										appStore={appStore} />
								} />
								<Route exact path={PATH_INBOX} render={
									(props) => <Inbox
										{...props}
										authenticationStore={authenticationStore}
										messageStore={messageStore}
										appStore={appStore} />
								} />
								<Route path={PATH_INBOX_MAIL + "/:id"} render={
									(props) => <Message
										{...props}
										authenticationStore={authenticationStore}
										messageStore={messageStore}
										appStore={appStore} />
								} />
								<Route path={PATH_CUSTOMERS} render={
									(props) => <Customers
										{...props}
										authenticationStore={authenticationStore}
										customersStore={customersStore}
										salesDataStore={salesDataStore}
										appStore={appStore} />
								} />
								<Route path={PATH_CURRENT_STATUS + "/:obfuscatedIds?"} render={
									(props) => <CurrentStatus
										{...props}
										authenticationStore={authenticationStore}
										customersStore={customersStore}
										salesDataStore={salesDataStore}
										appStore={appStore} />
								} />
								<Route path={PATH_FEATURED_VENDORS} render={
									(props) => <FeaturedVendors
										{...props}
										authenticationStore={authenticationStore}
										vendorsStore={vendorsStore}
										appStore={appStore} />
								} />
								<Route exact path={PATH_RANKING} render={
									(props) => <RankingList
										{...props}
										authenticationStore={authenticationStore}
										rankingListStore={rankingListStore}
										appStore={appStore} />
								} />
								<Route path={PATH_RANKING + "/:rank"} render={
									(props) => <Ranking
										{...props}
										authenticationStore={authenticationStore}
										rankingListStore={rankingListStore}
										appStore={appStore} />
								} />
								<Route path={PATH_USER_ACCOUNT} render={
									(props) => <UserAccount 
										{...props} 
										authenticationStore={authenticationStore}
										appStore={appStore} />
								} />
								{/* <Route path={PATH_ABOUT} component={About} /> */}
								<Route path={PATH_ABOUT} render={
									(props) => <About 
										{...props} 
										authenticationStore={authenticationStore}
										otherContentStore={otherContentStore} 
										appStore={appStore} />
								} />
								<Route path={PATH_MY_SALES_REP} render={
									(props) => <MySalesRep 
										{...props} 
										authenticationStore={authenticationStore}
										mySalesRepStore={mySaleRepStore} 
										appStore={appStore} />
								} />
								<Route path={PATH_PP} render={
									(props) => <PP 
										{...props} 
										otherContentStore={otherContentStore} 
										appStore={appStore} />
								} />
								<Route path={PATH_TOU} render={
									(props) => <TOU 
										{...props} 
										otherContentStore={otherContentStore} 
										appStore={appStore} />
								} />
								<Route path={PATH_404} component={NoMatch} />
								<Route path="/*" render={({location}) => {
									const pageView = `${location.pathname}${location.search}`;
									if (routeHistory !== pageView) {
										routeHistory = pageView;
										ReactGA.pageview(pageView);
										return <></>;
									}
									}} />
								<Redirect to={PATH_404} />
							</IonRouterOutlet>
						</IonReactRouter>
					</>
				}
			</IonApp>
		);
	}
}

export default App;
