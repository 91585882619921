import { observable, computed, action } from "mobx";
import ProductModel from "../models/ProductModel";
import BannerModel from "../models/BannerModel";
import Axios, { AxiosResponse } from 'axios';
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";

export default class ProductsStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;
    private _lastSearchId = 0;
    private _count = 0;
    @observable private _products: Array<ProductModel> = [];
    @observable private _banners: Array<BannerModel> = [];

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @computed get products() {
        return this._products;
    }
    
    @computed get banners() {
        return this._banners;
    }

    @action search(
        searchString: string, vendorId: number | undefined, promotionId: number | undefined,
        isFeatured: boolean, isDesc: boolean,
        screenWidth: number, count: number, shouldReset: boolean): Promise<number> {
        const searchId = ++this._lastSearchId;

        if (shouldReset) {
            this._products = [];
            this._count = 0;
        }

        const url = this._appRegistryStore.mobileApiUrl + "/v3/Products/Search";

        const productSearchData = {
            SearchString: searchString,
            CategoryId: -1,
            VendorId: vendorId !== undefined ? vendorId : -1,
            PromotionId: promotionId !== undefined ? promotionId : -1,
            SortByName: true,
            IsFeatured: isFeatured,
            Descending: isDesc,
            ScreenWidth: screenWidth,
            Skip: this._count,
            Top: count
        }

        this._count = this._count + count;

        return Axios.post<boolean, AxiosResponse<any>>(url, productSearchData, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
            .then((res: AxiosResponse<any>) => {
                if (searchId !== this._lastSearchId) return -1;

                res.data.forEach((item: any) => {
                    this._products.push({
                        id: item.Id,
                        number: item.Number,
                        name: item.Name,
                        description: item.Description,
                        points: item.Points,
                        vendorName: item.VendorName,
                        categoryName: item.CategoryName,
                        manufacturerName: item.ManufacturerName,
                        imageId: item.ImageId,
                        isFeatured: item.IsFeatured,
                        isDoublePoints: item.IsDoublePoints,
                        imageBytes: item.ImageBytes,
                    });
                });

                return res.data.length;
            });
    }

    @action getBanners() {
        this._banners = [];
        const url = this._appRegistryStore.mobileApiUrl + "/v2/Promotion/Type/Banner";
        // if(this._appRegistryStore.bannerUrl !== ""){
        //     this._banners.push(this._appRegistryStore.bannerUrl);
        // }
        return Axios.get<boolean, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
                .then((res: AxiosResponse<any>) => {
                    res.data.forEach((item: any) => {
                        this._banners.push({image:"data:image/jpeg;base64," +item.Image, URL: item.URL});
                    });
                    return res.data.length;
                });
    }
}
