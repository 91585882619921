import { IonImg, withIonLifeCycle, } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import Gauge from '../components/Gauge';
import '../theme/pages/goals.css';
import SalesDataStore from '../mobx/SalesDataStore';
import { observer } from 'mobx-react';
import { numberWithCommas, getformatNumber } from '../common/utils';
interface Props extends RouteComponentProps {
	salesDateStore: SalesDataStore;
}

type State = {
	isLoading: boolean,
}

@observer
class Goals extends React.Component<Props, State> {
	// state: State = {
	// 	isLoading: true,
	// }

	ionViewDidEnter() {
		// this.props.salesDateStore.fetch()
		// 	.finally(() => {
		// 		this.setState({ isLoading: false });
		// 	});
	}

	ionViewDidLeave() {

	}

	render() {
		const { salesData } = this.props.salesDateStore;

		// const sales = 50000;
		const sales = salesData?.ytdSales ? salesData?.ytdSales: 0;
		// const salesGoal = 100000;
		const salesGoal = salesData?.salesGoal ? salesData?.salesGoal: 0;
		const salesLeft = salesGoal - sales;
		const percentSalesCompleted = sales / salesGoal;

		// const points = 47687;
		const points = salesData?.ytdPoints ? salesData?.ytdPoints: 0;
		// const pointsGoal = 250000;
		const pointsGoal = salesData?.pointsGoal ? salesData?.pointsGoal: 0;
		const pointsLeft = pointsGoal - points;
		const percentPointsCompleted = points / pointsGoal;

		let messageState = 0;
		if(percentSalesCompleted < 1 && percentPointsCompleted < 1){
			messageState = 1;
		} else if(percentSalesCompleted >= 1 && percentPointsCompleted < 1){
			messageState = 2;
		} else if(percentSalesCompleted < 1 && percentPointsCompleted >= 1){
			messageState = 3;
		} else if(percentSalesCompleted >= 1 && percentPointsCompleted >= 1){
			messageState = 4;
		}
		console.log('message branchNumber', salesData?.branchNumber)
		console.log('message customerNumber', salesData?.customerNumber)
		console.log('message customerNumber', salesData, percentSalesCompleted, percentSalesCompleted >=1, percentPointsCompleted, percentPointsCompleted >=1 )
		return (
				<div className="goals">
					{salesData &&
						<div className="goals-container">
							<div className="goal">
								<div className="label text-center">Sales</div>
								<div className="percent-earned">{percentSalesCompleted >=1 ? <span className="goals-checkmark">✓</span>: (getformatNumber(percentSalesCompleted * 100, 0)) + "%" }</div>
								<div className="label left">{Math.max(0, salesLeft).toLocaleString("en-US", {style:"currency", currency:"USD"}) + " sales left"}</div>
								<Gauge fillValue={1-percentSalesCompleted} fillColor={"var(--ion-color-primary)"} backgroundColor={"gold"} />
								<div className="label earned">{Math.max(0, sales).toLocaleString("en-US", {style:"currency", currency:"USD"}) + " sales earned"}</div>
							</div>
							<div className="center" >
								<IonImg className="medal" src=".\assets\img\Medal.png"></IonImg>
								<div>
									{messageState === 1 && 
										<div className="message">
											You still have time qualify for the G4G Trip! Let's work on your Sales & Points.
										</div>
									}
									{messageState === 2 && 
										<div className="message">
											Good job meeting your Sales threshold!  Now you need {numberWithCommas(Math.max(0,pointsLeft))} Points to be on your way for qualifying for the G4G trip!
										</div>
									}
									{messageState === 3 && 
										<div className="message">
											Good job meeting your Points threshold! Now you just need {numberWithCommas(Math.max(0,salesLeft))} dollars to be on your way for qualifying for the G4G Trip!
										</div>
									}
									{messageState === 4 && 
										<div className="message">
											Good job meeting thresholds! You are on your way to qualifying for the G4G Trip!
										</div>
									}
								</div>
							</div>
							<div className="goal">
								<div className="label text-center">Points</div>
								<div className="percent-earned">{percentPointsCompleted>=1? <span className="goals-checkmark">✓</span>: (getformatNumber(percentPointsCompleted * 100, 0)) + "%"}</div>
								<div className="label left">{numberWithCommas(Math.max(0,pointsLeft)) + " points left"}</div>
								<Gauge fillValue={1-percentPointsCompleted} fillColor={"var(--ion-color-primary)"} backgroundColor={"gold"} />
								<div className="label earned">{numberWithCommas(points) + " points earned"}</div>
							</div>
						</div>
					}
				</div>
		);
	}
}

export default withIonLifeCycle(Goals);
