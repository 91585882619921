import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent,  IonLabel,  IonButton, IonIcon, IonSearchbar, IonList, withIonLifeCycle, IonInfiniteScroll, IonInfiniteScrollContent, IonText, IonItem } from '@ionic/react';
import React from 'react';
import CustomersItem from '../components/CustomersItem';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import SalesDataStore from '../mobx/SalesDataStore';
import CustomersStore from '../mobx/CustomersStore';
import { observer } from 'mobx-react';
import CustomerModel from '../models/CustomerModel';
import { arrowBack, funnel, close, arrowDown, arrowUp } from 'ionicons/icons';
import { PATH_LOGIN, PATH_HOME } from '../common/Constants';
/* Custom CSS */
import '../theme/pages/customers.css';
import AppStore from '../mobx/AppStore';

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	customersStore: CustomersStore;
	salesDataStore: SalesDataStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean,
	isSearching: boolean,
	searchString: string,
	isFiltersOpen: boolean,
	isDesc: boolean
}

type Member = {
	isMounted: boolean,
	infiniteScroll: React.RefObject<HTMLIonInfiniteScrollElement>
}

@observer
class Customers extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true,
		isSearching: false,
		searchString: "",
		isFiltersOpen: false,
		isDesc: false
	}

	member: Member = {
		isMounted: false,
		infiniteScroll: React.createRef<HTMLIonInfiniteScrollElement>()
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		if (this.props.authenticationStore.isAdmin()) {
			this.setState({ isLoading: true });
			this.props.authenticationStore.isValid()
				.then((isValid) => {
					if (this.member.isMounted) {
						if (isValid) {
							this.setState({ isHidden: false });
							this.search(true, true);
						} else {
							this.props.history.replace(PATH_LOGIN);
						}
					}
				})
				.catch(() => {
					this.props.history.replace(PATH_LOGIN);
				});
		} else {
			this.props.history.replace(PATH_HOME);
		}
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	search = (shouldLoad: boolean, shouldReset: boolean) => {
		this.setState({ isLoading: shouldLoad, isSearching: true });

		this.props.customersStore.search(
			this.state.searchString,
			this.state.isDesc,
			20,
			shouldReset)
			.then((length) => {
				if (length === -1) return;
				if (this.member.isMounted) {
					if (length) {
						this.member.infiniteScroll.current!.complete();
					} else {
						this.member.infiniteScroll.current!.disabled = true;
					}
				}
				this.setState({ isLoading: false, isSearching: false });
			})
			.catch(() => {
				this.setState({ isLoading: false, isSearching: false });
			});
	}

	onSearchInput = (input: string) => {
		this.setState({ searchString: input }, () => { this.search(false, true); });
	}

	toggleIsDesc = () => {
		this.setState({ isDesc: !this.state.isDesc }, () => { this.search(true, true); });
	}

	changeTop = () => {
		const filterTab = document.getElementById("content-filters");
		const mainTab = document.getElementById("content-main");
		if (filterTab && mainTab) {
			if (!this.state.isFiltersOpen) {
				filterTab.style.setProperty("transform", "translateY(0vh)");
				mainTab.style.setProperty("transform", "translateY(calc(var(--content-height)))");
				mainTab.style.setProperty("--offset-bottom", "calc(0vh - calc(var(--content-height)))", "important");
				this.setState({ isFiltersOpen: true });
			} else {
				filterTab.style.setProperty("transform", "translateY(calc( 5vh - var(--content-height)))");
				mainTab.style.setProperty("transform", "translateY(5vh)");
				mainTab.style.setProperty("--offset-bottom", "-5vh", "important");
				this.setState({ isFiltersOpen: false });
			}
		}
	}

	render() {
		return (
			<IonPage className="customers">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar mode="md" className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={() => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								{(!this.state.isFiltersOpen) ?
									<>
										<IonTitle onClick={this.changeTop} className="toolbar-title" >Customers</IonTitle>
										<IonIcon className="filter-icons" onClick={this.changeTop} slot="end" icon={funnel} />
									</>
									:
									<>
										<IonTitle onClick={this.changeTop} className="toolbar-title" >Filter</IonTitle>
										<IonIcon className="filter-icons" onClick={this.changeTop} slot="end" icon={close} />
									</>
								}
							</IonToolbar>
						</IonHeader>
						<IonContent className="content-filters" id="content-filters" >
							<div className="filter-area">
								<IonLabel className="sort-title">Sort By</IonLabel>
								<IonItem detail={false} lines="none" className="item-direction" button={true} onClick={this.toggleIsDesc}>
									<IonLabel>Name</IonLabel>
									<IonButton className="sort-direction" onClick={(e) => { this.toggleIsDesc(); e.stopPropagation(); }}><IonIcon icon={this.state.isDesc ? arrowDown : arrowUp} /></IonButton>
								</IonItem>
								<br />
								<IonLabel className="sort-title">Search</IonLabel>
								<IonSearchbar className="filter-search" placeholder="Customer Name" onInput={e => this.onSearchInput(e.currentTarget.value!)}></IonSearchbar>
							</div>
							<div className="curve"></div>
						</IonContent>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='products-toolbar'>
									<>
										<IonTitle onClick={this.changeTop} className="toolbar-title" >Customers</IonTitle>
									</>
									<>
										<IonItem lines="none" className="item-direction" button={true} onClick={this.toggleIsDesc}>
											<IonLabel>Sort Name</IonLabel>
											<IonButton className="sort-direction" onClick={(e) => { this.toggleIsDesc(); e.stopPropagation(); }}><IonIcon icon={this.state.isDesc ? arrowDown : arrowUp} /></IonButton>
										</IonItem>
										<IonItem lines="none" className="item-direction item-search">
										<IonSearchbar className="filter-search" placeholder="Customer Name" onInput={e => this.onSearchInput(e.currentTarget.value!)}></IonSearchbar>
										</IonItem>
									</>
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
					<>
						<IonContent className="content-main" id="content-main">
							{(this.state.isSearching || this.props.customersStore.customers.length !== 0) ?
								<IonList>
									{this.props.customersStore.customers.map((it: CustomerModel, index: number) => {
										return <CustomersItem
											key={index}
											customer={it} />
									})}
								</IonList> :
								<IonText class="text-center">Search did not match any customers</IonText>}

							<IonInfiniteScroll ref={this.member.infiniteScroll} threshold="100px" onIonInfinite={() => { this.search(false, false) }}>
								<IonInfiniteScrollContent loadingText="Loading customers..." >
								</IonInfiniteScrollContent>
							</IonInfiniteScroll>
						</IonContent>
					</>}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(Customers);