import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonList, IonRefresher, IonRefresherContent, isPlatform, IonButton, IonIcon, withIonLifeCycle, IonLabel, IonItem, IonInfiniteScroll, IonInfiniteScrollContent, IonText} from '@ionic/react';
import React from 'react';
import InboxMsg from '../components/InboxItem';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import { observer } from 'mobx-react';
import { arrowBack, arrowDown, arrowUp, close, funnel} from 'ionicons/icons';
import { PATH_LOGIN } from '../common/Constants';
import MessageModel from '../models/MessageModel';
import MessageStore from '../mobx/MessageStore';
/* Custom CSS */
import '../theme/pages/inbox.css';
import AppStore from '../mobx/AppStore';

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	messageStore: MessageStore;
	appStore: AppStore;
}

type State = {
	// @TODO stop fetching again when coming back from Message?
	isHidden: boolean,
	isLoading: boolean,
	isFetching: boolean,
	isFiltersOpen: boolean,
	isDesc: boolean
}

type Member = {
	isMounted: boolean,
	infiniteScroll: React.RefObject<HTMLIonInfiniteScrollElement>
}

@observer
class Inbox extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true,
		isFetching: false,
		isFiltersOpen: false,
		isDesc: true
	}

	member: Member = {
		isMounted: false,
		infiniteScroll: React.createRef<HTMLIonInfiniteScrollElement>()
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						this.setState({isHidden: false});
						this.fetchMessages(true, true);
					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	fetchMessages = (shouldLoad: boolean, shouldReset: boolean) => {
		this.setState({ isLoading: shouldLoad, isFetching: true });

		return this.props.messageStore.fetchMessages(this.state.isDesc, 200, shouldReset)
			.then((hasResult) => {
				if (this.member.isMounted) {
					// if (hasResult > 0) {
					// 	this.member.infiniteScroll.current!.complete();
					// } else {
					// 	this.member.infiniteScroll.current!.disabled = true;
					// }
				}
			})
			.finally(() => {
				this.setState({ isLoading: false, isFetching: false });
			});
	}

	doRefresh = (event: any) => {
		this.fetchMessages(false, true)
			.finally(() => {
				event.detail.complete();
			});
	}

	toggleIsDesc = () => {
		this.setState({isDesc: !this.state.isDesc}, () => { this.fetchMessages(true, true); });
	}

	changeTop = () => {
		const filterTab = document.getElementById("content-filters");
		const mainTab = document.getElementById("content-main");
		if (filterTab && mainTab){
			if (!this.state.isFiltersOpen){
				filterTab.style.setProperty("transform", "translateY(0vh)");
				mainTab.style.setProperty("transform", "translateY(calc(var(--content-height)))");
				mainTab.style.setProperty("--offset-bottom","calc(0vh - calc(var(--content-height)))", "important");
				this.setState({isFiltersOpen: true});
			} else {
				filterTab.style.setProperty("transform", "translateY(calc( 5vh - var(--content-height)))");
				mainTab.style.setProperty("transform", "translateY(5vh)");
				mainTab.style.setProperty("--offset-bottom","-5vh", "important");
				this.setState({isFiltersOpen: false});
			}
		}
	}

	render() {
		return (
			<IonPage className="inbox">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar mode="md" className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={ () => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								{(!this.state.isFiltersOpen) ? 
								<>	<IonTitle onClick={this.changeTop} className="toolbar-title" >Inbox</IonTitle>
									<IonIcon className="filter-icons" onClick={this.changeTop} slot="end" icon={funnel} />
								</> : 
		
								<>	<IonTitle onClick={this.changeTop} className="toolbar-title" >Filter</IonTitle>
								<IonIcon className="filter-icons" onClick={this.changeTop} slot="end" icon={close} /> </> 
								}
							</IonToolbar>
						</IonHeader>
						<IonContent className="content-filters" id="content-filters" >
							<div className="filter-area">
								<IonLabel className="sort-title">Sort By</IonLabel>
								<IonItem detail={false} lines="none" className="item-direction" button={true} onClick={this.toggleIsDesc}>
									<IonLabel>Date</IonLabel>
									<IonButton className="sort-direction" onClick={(e) => { this.toggleIsDesc(); e.stopPropagation(); }}><IonIcon icon={this.state.isDesc ? arrowDown : arrowUp} /></IonButton>
								</IonItem>
								<br />
							</div>
							<div className="curve"></div>
						</IonContent>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='products-toolbar header-toolbar'>
									<>
										<IonTitle  className="toolbar-title" >Inbox</IonTitle>
									</> 
									<>	
										<IonItem lines="none" className="item-direction" button={true} onClick={this.toggleIsDesc}>
											<IonLabel>Sort By Date</IonLabel>
											<IonButton className="sort-direction" onClick={(e) => { this.toggleIsDesc(); e.stopPropagation(); }}><IonIcon icon={this.state.isDesc ? arrowDown : arrowUp} /></IonButton>
										</IonItem>
									</>
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
				<>
				<IonContent className="content-main" id="content-main">
					<IonRefresher slot="fixed" onIonRefresh={this.doRefresh} pullFactor={0.5} pullMin={60} pullMax={200}>
						<IonRefresherContent
							pullingIcon="arrow-dropdown"
							pullingText="Pull to refresh"
							refreshingSpinner="crescent"
							refreshingText="Loading...">
						</IonRefresherContent>
					</IonRefresher>

					{(this.state.isFetching || this.props.messageStore.messages.length !== 0) ? 
					<IonList>
						{this.props.messageStore.messages.map((it: MessageModel, index: number) => {
							return <InboxMsg messageStore={this.props.messageStore} key={index} message={it}></InboxMsg>
						})}
					</IonList> :
					<IonText class="text-center">You have no messages</IonText>}

					{/* <IonInfiniteScroll ref={this.member.infiniteScroll} threshold="100px" onIonInfinite={() => {this.fetchMessages(false, false)}}>
						<IonInfiniteScrollContent loadingText="Loading messages..." >
						</IonInfiniteScrollContent>
					</IonInfiniteScroll> */}
				</IonContent>
				</>}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(Inbox);