import {  IonLoading } from '@ionic/react';
import React from 'react';

class Loading extends React.Component {

	render() {
		return (
			<IonLoading isOpen={true} />
		);
	}
};

export default Loading;
