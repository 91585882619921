import { observable, computed, action } from "mobx";
import VendorModel from "../models/VendorModel";
import Axios, { AxiosResponse } from 'axios';
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";

export default class VendorsStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;
    @observable private _vendors: Array<VendorModel> = [];

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @computed get vendors() {
        return this._vendors;
    }

    @action fetch(screenWidth: number) {
        this._vendors = [];
        const url = this._appRegistryStore.mobileApiUrl + "/v2/Vendors/ImageSize/" + screenWidth;
        return Axios.get<any, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
            .then((res: AxiosResponse<any>) => {                
                res.data.forEach((item: any) => {
                    this._vendors.push({
                        id: item.Id,
                        name: item.Name,
                        description: item.Description,
                        hasFeaturedProducts: item.HasFeaturedProducts,
                        vendorLevelId: item.VendorLevelId,
                        imageId: item.ImageId,
                        imageBytes: item.ImageBytes
                    });
                });
            });
    }
}