import { action } from "mobx";
import Axios, { AxiosResponse } from 'axios';
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";
import ENV from "../env/env";

export default class OtherContentStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @action fetch(goTo: string) {
        const url = ENV.REACT_APP_SITE_CORE_URL + '/' + goTo;
        return Axios.get<any, AxiosResponse<any>>(url)
            .then((res: AxiosResponse<any>) => {
                let parser = new DOMParser();
                const doc = parser.parseFromString(res.data, "text/html");
                //@ts-ignore
                // return doc.getElementsByClassName("avada-html-layout-wide")[0];
                //@ts-ignore
                return doc.getElementsByClassName("post-content")[0];
            });
    }
    @action fetchTOU() {
        const url = ENV.REACT_APP_SITE_CORE_URL + '/terms-of-use';
        return Axios.get<any, AxiosResponse<any>>(url)
            .then((res: AxiosResponse<any>) => {
                let parser = new DOMParser()
                const doc = parser.parseFromString(res.data, "text/html")
                //@ts-ignore
                return doc.getElementsByClassName("post-content")[0];
            });
    }
    @action fetchPP() {
        const url = ENV.REACT_APP_SITE_CORE_URL +'/privacy-policy';
        return Axios.get<any, AxiosResponse<any>>(url)
            .then((res: AxiosResponse<any>) => {
                let parser = new DOMParser()
                const doc = parser.parseFromString(res.data, "text/html")
                //@ts-ignore
                return doc.getElementsByClassName("post-content")[0];
            });
    }
}