import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonGrid, IonRow, IonCol, IonItem, IonInput, IonButton, withIonLifeCycle, IonIcon, IonLabel } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import { arrowBack } from 'ionicons/icons';
import { PATH_LOGIN, PATH_HOME } from '../common/Constants';
import '../theme/pages/userAccount.css';
import AppStore from '../mobx/AppStore';
interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean
}

type Member = {
	isMounted: boolean
}

class UserAccount extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true
	}

	member: Member = {
		isMounted: false
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						this.setState({ isHidden: false, isLoading: false });
					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	render() {
		return (
			<IonPage className="user-account">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={() => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								<IonTitle  className="toolbar-title" >User Account</IonTitle>
							</IonToolbar>
							<div className="curve-static"></div>
						</IonHeader>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='header-toolbar'>
									<>
										<IonTitle  className="toolbar-title" >User Account</IonTitle>
									</> 
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
					<>
						<IonContent>
							<IonGrid className="custom-width">
								{this.props.authenticationStore.user &&
								<>
								<IonRow>
									<IonCol className="name">
										{this.props.authenticationStore.user!.firstName + " " + this.props.authenticationStore.user!.lastName}
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<IonLabel className="grid-label">Current Email Address:</IonLabel>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<IonItem className="inputs" lines="none" disabled={true}>
											{this.props.authenticationStore.user!.email}
										</IonItem>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<IonLabel className="grid-label">Update Email Address:</IonLabel>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<IonItem className="inputs">
											<IonInput placeholder="New email address"></IonInput>
										</IonItem>
									</IonCol>
								</IonRow>
								<IonRow className="buttons-row">
									<IonCol>
										<IonButton className="buttons cancel" routerLink={PATH_HOME}>Cancel</IonButton>
									</IonCol>
									<IonCol>
										<IonButton className="buttons">Update Info</IonButton>
									</IonCol>
								</IonRow>
								</>
								}
							</IonGrid>
						</IonContent>
					</>}
				{this.state.isLoading && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(UserAccount);