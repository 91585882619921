import { IonItem, IonLabel, IonItemSliding, IonItemOptions, IonItemOption, IonIcon, IonModal, IonButton, } from '@ionic/react';
import React from 'react';
import { PATH_INBOX_MAIL } from '../common/Constants';
import { MessageStatusTypes } from '../enums/MessageStatusTypes';
import MessageModel from '../models/MessageModel';
import { trash, arrowForward } from 'ionicons/icons';
import MessageStore from '../mobx/MessageStore';

interface Props {
	message: MessageModel,
	messageStore: MessageStore
}

type State = {
	isAlertOpen: boolean,
	isDeleted: boolean
}


class InboxItem extends React.Component<Props> {

	state: State = {
		isAlertOpen: false,
		isDeleted: false
	}

	getDate = () => {
		const event = new Date(this.props.message.SentDateTime || "");

		const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };

		//console.log(event.toLocaleDateString('en-US', options));

		// return event.toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' });
		return event.toLocaleDateString('en-US', options as any);

	}

	alertDelete = (change: boolean) => {
		this.setState({ isAlertOpen: change });
	}

	delete = (id: number) => {
		this.props.messageStore.setMessageStatus(id, MessageStatusTypes.Deleted)
			.then(() => {
				this.alertDelete(false);
				this.setState({ isDeleted: true });
			});
	}

	render() {
		return (
			<>
			{!this.state.isDeleted &&
				<IonItemSliding className="custom-width">
					<IonItem detail={false} className="item-inbox" lines="none" routerLink={PATH_INBOX_MAIL + "/" + this.props.message.Id} >
						<div slot="start" className={this.props.message.StatusType === MessageStatusTypes.Unread ? 'unread' : ''}></div>
						<IonLabel class="ion-text-wrap">
							<p className="subject">{this.props.message.Title}</p>
							<p className="date">{this.getDate()}</p>
						</IonLabel>
						<IonIcon icon={arrowForward}/>
					</IonItem>
					<IonItemOptions side="end">
						<IonItemOption  onClick={() => this.alertDelete(true)} color="danger">
							<IonIcon slot="icon-only" icon={trash}></IonIcon>
						</IonItemOption>
					</IonItemOptions>
					<IonModal cssClass="inbox-custom-alert" onDidDismiss={() => {this.setState({ isAlertOpen: false });}} isOpen={this.state.isAlertOpen}>
						<p>Are you sure you want to delete this message?</p>
						<div className="alert-buttons">
							<IonButton onClick={() => this.delete(this.props.message.Id)}>Yes</IonButton>
							<IonButton onClick={() => this.alertDelete(false)}>No</IonButton>
						</div>
					</IonModal>
				</IonItemSliding>
			}
			</>
		);
	}
};

export default InboxItem;