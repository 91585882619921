import { observable, computed, action } from "mobx";
import MessageModel from "../models/MessageModel";
import AppRegistryStore from "./AppRegistryStore";
import AuthenticationStore from "./AuthenticationStore";
import Axios, { AxiosResponse } from "axios";
import { CLIENT_APPLICATION_CODE } from "../common/Constants";
import { MessageStatusTypes } from "../enums/MessageStatusTypes";

export default class MessageStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;
    private _lastSearchId = 0;
    private _count = 0;
    @observable private _messages: Array<MessageModel> = [];
    @observable private _selectedMessage: MessageModel | null = null;
    @observable private _messageCount: number = 0;

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @computed get messages() {
        return this._messages;
    }

    @computed get selectedMessage() {
        return this._selectedMessage;
    }
    @computed get getMessageCount() {
        return this._messageCount;
    }

    @action fetchMessages(isDesc: boolean, count: number, shouldReset: boolean) {
        const searchId = ++this._lastSearchId;

        if (shouldReset) {
            this._messages = [];
            this._count = 0;
        }

		const sortBy = "SentDateTime";

        const url = this._appRegistryStore.mobileApiUrl + `/v2/Notification?sortBy=${sortBy}&isDesc=${isDesc}`;

        return Axios.get<boolean, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
            .then((res: AxiosResponse<Array<MessageModel>>) => {
                if (searchId !== this._lastSearchId) return false;
                if(res.data.length > 0) {
                    res.data.forEach((message:MessageModel) => {
                        if(message.StatusType !== MessageStatusTypes.Deleted)
                            this._messages.push(message);
                    });
                }

                return this._messages.length;
            });
        // return Axios.post<any, AxiosResponse<Array<MessageModel>>>(url, data, { headers: { Authorization: this._authenticationStore.user?.notificationSvcAuthToken } })
        //     .then((res: AxiosResponse<Array<MessageModel>>) => {
        //         if (searchId !== this._lastSearchId) return false;
        //         if(res.data.length > 0) {
        //             res.data.forEach((message) => {
        //                 //removed Deleted massages from showing
        //                 if(message.messageStatusType !== MessageStatusTypes.Deleted)
        //                     this._messages.push(message);
        //             });
        //         }

        //         return this._messages.length;
        //     });
    }

    @action getUnreadMessagesCount(){
        // const url = this._appRegistryStore.bnsMobileApiUrl + "/v2/Client/MessageGroups/Messages/Unread/Count";
        const url = this._appRegistryStore.mobileApiUrl + "/v2/Notification/Unread/Count";
        
        // const data = {
        //     MessageGroupCodes: this._authenticationStore.user?.messageGroupCodes,
        //     ClientUserName: this._authenticationStore.user?.email,
        //     ClientApplicationCode: CLIENT_APPLICATION_CODE
        // }

        return Axios.get<boolean, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
        // return Axios.get<any, AxiosResponse<number>>(url, data, { timeout: 20000,  headers: { Authorization: this._authenticationStore.user?.notificationSvcAuthToken } })
            .then((res: AxiosResponse<number>) => {
                this._messageCount = res.data;
                return res.data;
            });
        // return Axios.post<any, AxiosResponse<number>>(url, data, { timeout: 20000,  headers: { Authorization: this._authenticationStore.user?.notificationSvcAuthToken } })
        //     .then((res: AxiosResponse<number>) => {
        //         this._messageCount = res.data;
        //         return res.data;
        //     });
    }
    
    @action setMessageStatus(id: number, status: MessageStatusTypes) {
        // const url = this._appRegistryStore.bnsMobileApiUrl + "/v1/Client/SetMessageStatus";

        // const data = {
        //     MessageStatusType: status,
        //     MessageId: id,
        //     ClientUserName: this._authenticationStore.user?.email,
        //     ClientApplicationCode: CLIENT_APPLICATION_CODE
        // };
        const url = this._appRegistryStore.mobileApiUrl + "/v2/Notification/SetMessageStatus";
        

        const data = {
            StatusType: status,
            NotificationId: id,
            // ClientUserName: this._authenticationStore.user?.email,
            // ClientApplicationCode: CLIENT_APPLICATION_CODE
        };
        // return Axios.get<boolean, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
        // // return Axios.get<any, AxiosResponse<number>>(url, data, { timeout: 20000,  headers: { Authorization: this._authenticationStore.user?.notificationSvcAuthToken } })
        //     .then((res: AxiosResponse<number>) => {
        //         this._messageCount = res.data;
        //         return res.data;
        //     });
        return Axios.put<any, AxiosResponse<any>>(url, data, { headers: { Authorization: "Bearer " + this._authenticationStore.token } });
    }

    @action selectMessage(id: number) {
        const message = this._messages.find((message: MessageModel) => message.Id === id);

        if (message === undefined) {
            this._selectedMessage = null;
            return false;
        } else {
            this._selectedMessage = message;
            return true;
        }
    }
}