import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonButton, IonIcon, withIonLifeCycle, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonItem, IonRadioGroup, IonRadio } from '@ionic/react';
import React from 'react';
import RankingListItem from '../components/RankingListItem';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import RankingListStore from '../mobx/RankingListStore';
import { observer } from 'mobx-react';
import RankingModel from '../models/RankingModel';
import { arrowBack, funnel, close, arrowDown, arrowUp } from 'ionicons/icons';
import { PATH_LOGIN } from '../common/Constants';
import '../theme/pages/rankingList.css';
import { RankingSortTypes } from '../enums/RankingSortTypes';
import AppStore from '../mobx/AppStore';
interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	rankingListStore: RankingListStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean,
	isFiltersOpen: boolean,
	sortBy: RankingSortTypes,
	isDesc: boolean,
	sortByRadio: string
}

type Member = {
	isMounted: boolean,
	infiniteScroll: React.RefObject<HTMLIonInfiniteScrollElement>
}

@observer
class RankingList extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true,
		isFiltersOpen: false,
		sortBy: RankingSortTypes.Rank,
		isDesc: false,
		sortByRadio: "Rank"
	}

	member: Member = {
		isMounted: false,
		infiniteScroll: React.createRef<HTMLIonInfiniteScrollElement>(),
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						this.setState({ isHidden: false });
						this.props.rankingListStore.fetch()
							.then(() => {
								this.load(true);
							})
							.finally(() => {
								this.setState({ isLoading: false });
							});
					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	load = (shouldReset: boolean) => {
		if (this.props.rankingListStore.load(this.state.sortBy, this.state.isDesc, 40, shouldReset)) {
			this.member.infiniteScroll.current?.complete();
		} else {
			if (this.member.infiniteScroll.current !== null) {
				this.member.infiniteScroll.current!.disabled = true;
			}
		}
	}

	setSortBy(sortBy: RankingSortTypes) {
		this.setState({ sortBy: sortBy }, () => {
			this.load(true);
		});
	}

	setSortByRadio(inSortBy: string) {
		switch (inSortBy) {
			case "Rank":
				this.setSortBy(RankingSortTypes.Rank);
				break;
			case "Name":
				this.setSortBy(RankingSortTypes.Name);
				break;
			default:
				this.setSortBy(RankingSortTypes.Rank);
		}

	}

	toggleIsDesc = () => {
		this.setState({ isDesc: !this.state.isDesc }, () => {
			this.load(true);
		});
	}

	changeTop = () => {
		const filterTab = document.getElementById("content-filters");
		const mainTab = document.getElementById("content-main");
		if (filterTab && mainTab) {
			if (!this.state.isFiltersOpen) {
				filterTab.style.setProperty("transform", "translateY(0vh)");
				mainTab.style.setProperty("transform", "translateY(calc(var(--content-height)))");
				mainTab.style.setProperty("--offset-bottom", "calc(0vh - calc(var(--content-height)))", "important");
				this.setState({ isFiltersOpen: true });
			} else {
				filterTab.style.setProperty("transform", "translateY(calc( 5vh - var(--content-height)))");
				mainTab.style.setProperty("transform", "translateY(5vh)");
				mainTab.style.setProperty("--offset-bottom", "-5vh", "important");
				this.setState({ isFiltersOpen: false });
			}
		}
	}

	render() {
		return (
			<IonPage className="ranking-list">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar mode="md" className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={() => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								{(!this.state.isFiltersOpen) ?
									<>
										<IonTitle onClick={this.changeTop} className="toolbar-title" >Ranking List</IonTitle>
										<IonIcon className="filter-icons" onClick={this.changeTop} slot="end" icon={funnel} />
									</>
									:
									<>
										<IonTitle onClick={this.changeTop} className="toolbar-title" >Filter</IonTitle>
										<IonIcon className="filter-icons" onClick={this.changeTop} slot="end" icon={close} />
									</>
								}
							</IonToolbar>
						</IonHeader>
						<IonContent className="content-filters" id="content-filters" >
							<div className="filter-area">
								<IonLabel className="sort-title">Sort By</IonLabel>
								<IonRadioGroup onIonChange={e => { this.setSortByRadio(e.detail.value); }}>
									<IonItem>
										<IonLabel>Name</IonLabel>
										<IonRadio className="checkmark" slot="end" value="Name" />
									</IonItem>
									<IonItem>
										<IonLabel>Rank</IonLabel>
										<IonRadio slot="end" value="Rank" />
									</IonItem>
								</IonRadioGroup>
								<IonItem detail={false} lines="none" className="item-direction" button={true} onClick={this.toggleIsDesc}>
									<IonLabel>Direction</IonLabel>
									<IonButton className="sort-direction" onClick={(e) => { this.toggleIsDesc(); e.stopPropagation(); }}><IonIcon icon={this.state.isDesc ? arrowDown : arrowUp} /></IonButton>
								</IonItem>
								<br />
							</div>
							<div className="curve"></div>
						</IonContent>					
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='header-toolbar'>
									<>
										<IonTitle  className="toolbar-title" >Ranking List</IonTitle>
									</> 
									<>	
										<IonItem lines="none" className="item-direction" button={true} onClick={this.toggleIsDesc}>
											<IonLabel>Direction</IonLabel>
											<IonButton className="sort-direction" onClick={(e) => { this.toggleIsDesc(); e.stopPropagation(); }}><IonIcon icon={this.state.isDesc ? arrowDown : arrowUp} /></IonButton>
										</IonItem>
										<IonLabel className="sort-title"></IonLabel>
										<IonRadioGroup onIonChange={e => { this.setSortByRadio(e.detail.value); }}>
											<IonItem lines="none">
												<IonLabel>Name</IonLabel>
												<IonRadio className="checkmark" slot="end" value="Name" />
											</IonItem>
											<IonItem lines="none">
												<IonLabel>Sort: By Rank</IonLabel>
												<IonRadio slot="end" value="Rank" />
											</IonItem>
										</IonRadioGroup>
									</>
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
					<>
						<IonContent className="content-main" id="content-main">
							{this.props.rankingListStore.rankingList.map((it: RankingModel, index: number) => {
								return <RankingListItem key={index} ranking={it} ></RankingListItem>
							})}

							<IonInfiniteScroll ref={this.member.infiniteScroll} threshold="100px" onIonInfinite={() => { this.load(false) }}>
								<IonInfiniteScrollContent loadingText="Loading ranking..." >
								</IonInfiniteScrollContent>
							</IonInfiniteScroll>
						</IonContent></>}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};
export default withIonLifeCycle(RankingList);