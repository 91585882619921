import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonItem, IonLabel, withIonLifeCycle, IonButton, IonIcon, IonText } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import MessageStore from '../mobx/MessageStore';
import { isStringANumber } from '../common/utils';
import { arrowBack } from 'ionicons/icons';
import { PATH_LOGIN, PATH_INBOX } from '../common/Constants';
import { observer } from 'mobx-react';
import { MessageStatusTypes } from '../enums/MessageStatusTypes';
/* Custom CSS */
import '../theme/pages/message.css';
import AppStore from '../mobx/AppStore';
type MatchParams = {
	id: string
}

interface Props extends RouteComponentProps<MatchParams> {
	authenticationStore: AuthenticationStore;
	messageStore: MessageStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean
}

type Member = {
	isMounted: boolean
}

@observer
class Message extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true
	}

	member: Member = {
		isMounted: false
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		if (isStringANumber(this.props.match.params.id)) {
			this.props.authenticationStore.isValid()
				.then((isValid) => {
					if (this.member.isMounted) {
						if (isValid) {
							if (this.props.messageStore.selectMessage(parseInt(this.props.match.params.id))) {
								this.setState({ isHidden: false, isLoading: false });

								// doesn't matter if this fails
								this.props.messageStore.setMessageStatus(this.props.messageStore.selectedMessage!.Id, MessageStatusTypes.Read);
							} else {
								if (this.props.history.action === "PUSH") { // coming from inbox
									this.props.history.goBack();
								} else {
									this.props.history.replace(PATH_INBOX);
								}
							}
						} else {
							this.props.history.replace(PATH_LOGIN);
						}
					}
				})
				.catch(() => {
					this.props.history.replace(PATH_LOGIN);
				});
		} else {
			this.props.history.replace(PATH_INBOX);
		}
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	delete = () => {
		this.setState({ isLoading: true });
		this.props.messageStore.setMessageStatus(this.props.messageStore.selectedMessage!.Id, MessageStatusTypes.Deleted)
			.then(() => {
				if (this.member.isMounted) {
					if (this.props.history.action === "PUSH") { // coming from inbox
						this.props.history.goBack();
					} else {
						this.props.history.replace(PATH_INBOX);
					}
				}
			});
	}

	getDate = () => {
		if (this.props.messageStore.selectedMessage?.SentDateTime) {
			const event = new Date(this.props.messageStore.selectedMessage.SentDateTime || "");

			const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };

			return event.toLocaleDateString('en-US', options as any);
		}
	}

	render() {
		return (
			<IonPage className="message">
				{!this.props.appStore.isWeb ?
					<>
					<IonHeader>
						<IonToolbar className="header-toolbar">
							<IonTitle className="header-title">Inbox</IonTitle>
							<IonButtons slot="start">
								<IonButton onClick={() => { 
									this.props.history.goBack(); 
									}}><IonIcon icon={arrowBack} /></IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="curve-static"></div>
					</IonHeader>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='products-toolbar'>
									<>
										<IonTitle  className="toolbar-title" >Inbox</IonTitle>
									</> 
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
					<>
						<IonContent>
							<IonItem className="message-top custom-width" lines="none">
								<IonLabel className="subject-label">
									<b>{this.props.messageStore.selectedMessage?.Title} </b>
									<p>{this.getDate()}</p>
								</IonLabel>
							</IonItem>
							<IonItem lines="none" className="message-body custom-width">
								<IonText>
									<p>MESSAGE:</p>
									<p>{this.props.messageStore.selectedMessage?.MessageText}</p>
								</IonText>
							</IonItem>
							{/* <IonButton onClick={() => this.delete()}>Delete</IonButton> */}
						</IonContent>
					</>}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(Message);