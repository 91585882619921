import AppRegistryStore from "./AppRegistryStore";
import AuthenticationStore from "./AuthenticationStore";
import { action, computed, observable } from "mobx";
import Axios, { AxiosResponse } from "axios";
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';

import MessageStore from '../mobx/MessageStore';
import { PATH_INBOX_MAIL } from "../common/Constants";
const { PushNotifications } = Plugins;

interface NotificationData {
	notificationId: number;
}

export default class PushNotificationStore {
		private _appRegistryStore: AppRegistryStore;
		private _authenticationStore: AuthenticationStore;
		private _messageStore: MessageStore;
		private _hasInitialized: boolean = false;
		
		@observable private _showToastPush: boolean = false;
		@observable private _pushRedirectTo: string | null = null;

		@computed get showToastPush() {
				return this._showToastPush;
		}

		@computed get pushRedirectTo() {
			return this._pushRedirectTo;
		}

		setShowToastPush(state: boolean) {
			this._showToastPush = state;
		}

		setPushRedirectTo(state:  string | null) {
			this._pushRedirectTo = state;
		}

		constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore, messageStore: MessageStore) {
				this._appRegistryStore = appRegistryStore;
				this._authenticationStore = authenticationStore;
				this._messageStore = messageStore;
		}

		@action async initPushNotification() {
			if (this._hasInitialized) {
				return;
			}

			console.info("Init Push Notification");

			// Request permission to use push notifications
			// iOS will prompt user and return if they granted permission or not
			// Android will just grant without prompting
			PushNotifications.requestPermission().then(result => {
			// @TODO test this permission request
				if (result.granted) {
					PushNotifications.register();
				} else {
					// Show some error
				}
			});

			// On success, we should be able to receive notifications
			PushNotifications.addListener('registration',
				(tokenPN: PushNotificationToken) => {
					const url = this._appRegistryStore.mobileApiUrl + "/v2/Notification/Push/Register";
					const data = {
							token: tokenPN.value
					};
					console.log("reg tok: ", tokenPN.value);
					Axios.post<any, AxiosResponse<any>>(url, data, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
					.then(() => {
						console.info("Push Notification Registered")
					})
					.catch(() => {
						console.error("Push Notification Registration Error");
					});
				}
			);

			// Some issue with your setup and push will not work
			PushNotifications.addListener('registrationError',
				(err) => {
					// @TODO handle
					console.error('Push notification registration error: ' + JSON.stringify(err));
				}
			);

			// Show us the notification payload if the app is open on our device
			PushNotifications.addListener('pushNotificationReceived',
				(notification: PushNotification) => {
					console.info('pushNotificationReceived');

					// update unread count
					this._messageStore.getUnreadMessagesCount();
					this._showToastPush = true;
				}
			);

			// Method called when tapping on a notification
			PushNotifications.addListener('pushNotificationActionPerformed',
				async (notification: PushNotificationActionPerformed) => {
					console.info('pushNotificationActionPerformed');
					const notificationData = notification.notification.data as NotificationData;
					// Re route to mail Id

					// fetch messages first or will go to inbox instead
					await this._messageStore.fetchMessages(true, 200, true);
					this._pushRedirectTo = PATH_INBOX_MAIL + "/" + notificationData.notificationId;

					// update unread count
					this._messageStore.getUnreadMessagesCount();
					}
			);

			this._hasInitialized = true;
		}
}

