import { observable, computed, action } from "mobx";
import CustomerModel from "../models/CustomerModel";
import Axios, { AxiosResponse } from 'axios';
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";

export default class MySalesRepStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @action get(): Promise<boolean> {
        const url = this._appRegistryStore.mobileApiUrl + "/v2/SalesRep/Customer";
        return Axios.get<any, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
            .then((res: AxiosResponse<any>) => {
                return res.data;
            }).catch((err)=>{
            });
    }
}