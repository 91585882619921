import { observable, computed, action } from "mobx";
import { BarSalesData } from "../components/BarElement";
import Axios, { AxiosResponse } from "axios";
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";
import SalesDataModel from "../models/SalesDataModel";

export interface TripDetails {
	name: string,
	salesGoal: number,
	pointsGoals: number[],
	growthGoals: number[],
}

export default class SalesDataStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;
    @observable private _salesData: SalesDataModel | null = null;
    @observable private _barSalesDataList: Array<BarSalesData> = [];
	@observable private _barBonusSalesDataList: Array<BarSalesData> = [];
    @observable private _hasNoData: boolean = false;

	// @TODO get real data from DB, this is information only
	@observable private _tripDetails: TripDetails = {
		name: "Main Trip",
		salesGoal: 115000,
		pointsGoals: [172500,218500,264500,310500,356500,402500],
		growthGoals: [30,25,20,15,10,5]
	};
	@observable private _bonusTripDetails: TripDetails | null = {
		name: "Bonus Trip",
		salesGoal: 431250,
		pointsGoals: [477250,603750,730250,856750,983250,1109750],
		growthGoals: [30,25,20,15,10,5]
	};

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @computed get salesData() {
        return this._salesData;
    }
    @computed get barSalesDataList() {
        return this._barSalesDataList;
    }
	@computed get barBonusSalesDataList() {
		return this._barBonusSalesDataList;
	}
    @computed get hasNoData() {
        return this._hasNoData;
    }

	@computed get tripDetails() {
		return this._tripDetails;
	}

	@computed get bonusTripDetails() {
		return this._bonusTripDetails;
	}

    @action fetch(obfuscatedIds?: string) {
        this._salesData = null;
        this._barSalesDataList = [];

        let url;
        if (obfuscatedIds !== undefined && this._authenticationStore.isAdmin()) {
            const ids = JSON.parse(atob(obfuscatedIds!));
			url = this._appRegistryStore.mobileApiUrl + "/v2/SalesRep/CustomerNumber/" + ids.customerId + "/BranchNumber/" + ids.branchId;
        } else {
            url = this._appRegistryStore.mobileApiUrl + "/v3/ParticipantSalesData";
        }

        return Axios.get<any, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
            .then((res: AxiosResponse<any>) => {
                if(res.data.BranchNumber === 0 && res.data.CustomerNumber === 0) {
                    this._hasNoData = true;
                } else {
                    this._hasNoData = false;
                }

                this._salesData = {
                    branchNumber: res.data.BranchNumber,
                    customerNumber: res.data.CustomerNumber,

                    ytdSales: res.data.YtdSales,
                    ytdPercentGrowth: res.data.YtdPercentGrowth,
                    ytdPoints: res.data.YtdPoints,
					// ytdSales: 500000,
                    // ytdPercentGrowth: 9,
                    // ytdPoints: 300000,

                    projectedSales: res.data.ProjectedSales,
                    projectedPercentGrowth: res.data.ProjectedPercentGrowth,
                    projectedPoints: res.data.ProjectedPoints,
                    salesGoal: res.data.SalesGoal,
                    percentGrowthGoal: res.data.PercentGrowthGoal,
                    pointsGoal: res.data.PointsGoal,
                    projectedSalesGoalPercent: res.data.ProjectedSalesGoalPercent,
                    projectedPointsGoalPercent: res.data.ProjectedPointsGoalPercent,
                    projectedPercentGrowthGoalPercent: res.data.projectedPercentGrowthGoalPercent,
                    popupMessage: res.data.PopupMessage,
                    lastUpdate: res.data.LastUpdate,

					yoyPercentGrowth: res.data.YoyPercentGrowth
					// yoyPercentGrowth: -7
                }

				this._prepareBarData();
            });
    }

	private 

	private _prepareBarData() {
		const salesData = this._salesData;
		const tripDetails = this._tripDetails;
		const bonusTripDetails = this._bonusTripDetails;

		if (salesData == null) {
			return;
		}

		const currentPointTierIndex = tripDetails.pointsGoals.findIndex(pGoal => salesData.ytdPoints < pGoal) -1;

		this._barSalesDataList = [
			{
				value: salesData.ytdSales,
				projected: salesData.projectedSales,
				goals: [tripDetails.salesGoal],
				goalPercent: salesData.projectedSalesGoalPercent
			},
			{
				value: salesData.ytdPoints,
				projected: salesData.projectedPoints,
				goals: tripDetails.pointsGoals,
				goalPercent: salesData.projectedPointsGoalPercent
			},
			{
				value: salesData.ytdPercentGrowth,
				projected: salesData.projectedPercentGrowth,
				goals: [tripDetails.growthGoals[currentPointTierIndex > 0 ? currentPointTierIndex : 0]],
				goalPercent: salesData.projectedPercentGrowthGoalPercent
			}
		]

		if (bonusTripDetails == null) {
			return;
		}

		const bonusPointTierIndex = bonusTripDetails.pointsGoals.findIndex(pGoal => salesData.ytdPoints < pGoal) -1;

		this._barBonusSalesDataList = [
			{
				value: salesData.ytdSales,
				projected: salesData.projectedSales,
				goals: [bonusTripDetails.salesGoal],
				goalPercent: salesData.projectedSalesGoalPercent
			},
			{
				value: salesData.ytdPoints,
				projected: salesData.projectedPoints,
				goals: bonusTripDetails.pointsGoals,
				goalPercent: salesData.projectedPointsGoalPercent
			},
			{
				value: salesData.ytdPercentGrowth,
				projected: salesData.projectedPercentGrowth,
				goals: [bonusTripDetails.growthGoals[bonusPointTierIndex > 0 ? bonusPointTierIndex : 0]],
				goalPercent: salesData.projectedPercentGrowthGoalPercent
			}
		]
	}
}