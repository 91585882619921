import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonImg, IonItem, IonLabel, withIonLifeCycle, IonButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import Copyright from '../components/Copyright';
import ProductStore from '../mobx/ProductStore';
import { isStringANumber } from '../common/utils';
import { observer } from 'mobx-react';
/* Custom CSS */
import '../theme/pages/productDetail.css';
import { arrowBack } from 'ionicons/icons';
import { PATH_LOGIN } from '../common/Constants';
import AppStore from '../mobx/AppStore';

type MatchParams = {
	id: string
}

interface Props extends RouteComponentProps<MatchParams> {
	authenticationStore: AuthenticationStore;
	productStore: ProductStore;
	appStore: AppStore;
	parentPath: string;
}

type State = {
	isHidden: boolean,
	isLoading: boolean
}

type Member = {
	isMounted: boolean
}

@observer
class ProductDetail extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true
	}

	member: Member = {
		isMounted: false
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		if (isStringANumber(this.props.match.params.id)) {
			this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						this.setState({isHidden: false});
						this.props.productStore.fetch(parseInt(this.props.match.params.id), 200)
							.finally(() => {
								this.setState({ isLoading: false });
							});
					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
		}
		else {
			this.props.history.replace(this.props.parentPath);
		}
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	downloadMedia(isZipped: boolean) {
		this.props.productStore.downloadMedia(isZipped);
	}

	render() {
		return (
			<IonPage className="product-detail">
				{this.state.isHidden ? null :
				<>
				{!this.props.appStore.isWeb ? 
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={ () => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
						</IonButtons>
						<IonTitle className="toolbar-title" >Product Detail</IonTitle>
					</IonToolbar>
				</IonHeader>
				:
				<IonHeader>
					<IonToolbar>
						<IonTitle className="toolbar-title" >Product Detail</IonTitle>
					</IonToolbar>
				</IonHeader>
				}

				<IonContent>
					<IonItem className="product-detail-img-container" lines="none">
						<IonImg className="product-detail-img" src={ this.props.productStore.product?.imageBytes !== "" ? "data:image/jpeg;base64," + this.props.productStore.product?.imageBytes : "./assets/img/product-no-img.PNG"}></IonImg>
						<div className="img-fore">
							<div className="content">
								<IonLabel>
									{this.props.productStore.product?.points}
									<p>points</p>
								</IonLabel>
							</div>
							<div className="content clickable" onClick={() => this.downloadMedia(false)}>
								<IonLabel className="icon">
									<IonIcon src="./assets/svg/file.svg" />
									<p>media</p>
								</IonLabel>
							</div>
							<div className="content clickable" onClick={() => this.downloadMedia(true)}>
								<IonLabel className="icon">
									<IonIcon src="./assets/svg/zip.svg" />
									<p>media</p>
								</IonLabel>
							</div>
						</div>
					</IonItem>

					<IonItem className="custom-width" lines="none">
						<IonLabel className="product-detail-name" >{this.props.productStore.product?.name}</IonLabel>
					</IonItem>

					<IonItem className="custom-width" lines="none">
						<IonLabel className="product-detail-description ">{this.props.productStore.product?.description}</IonLabel>
					</IonItem>

					<IonGrid className="custom-width">
						<IonRow>
							<IonCol>
								<IonLabel className="product-detail-labels">SKU:</IonLabel>
							</IonCol>
							<IonCol>
								<IonLabel class="ion-text-end product-values-color">{this.props.productStore.product?.number}</IonLabel>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonLabel className="product-detail-labels">VENDOR:</IonLabel>
							</IonCol>
							<IonCol>
								<IonLabel class="ion-text-end product-values-color">{this.props.productStore.product?.vendorName}</IonLabel>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonLabel className="product-detail-labels">CATEGORY:</IonLabel>
							</IonCol>
							<IonCol>
								<IonLabel class="ion-text-end product-values-color">{this.props.productStore.product?.categoryName}</IonLabel>
							</IonCol>
						</IonRow>
					</IonGrid>
					<Copyright />
				</IonContent>
				</>}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(ProductDetail);
