import { action, observable, computed } from 'mobx';
import AuthenticationModel from '../models/AuthenticationModel';
import UserModel from '../models/UserModel';
import { Plugins } from '@capacitor/core';
import ResetPasswordModel from '../models/ResetPasswordModel';
import Axios, { AxiosResponse } from 'axios';
import { HttpStatus } from '../common/Constants';
import LoginResult from '../models/LoginResult';
import AppRegistryStore from './AppRegistryStore';

const { Storage } = Plugins;

const KEY_USER = "authentication_user";
const KEY_TOKEN = "authentication_token";

export default class AuthenticationStore {
    private _appRegistryStore: AppRegistryStore;
    private _user: UserModel | null = null;
    private _token: string | null = null;
    @observable _isLoggedIn: boolean = false;

    constructor(appRegistryStore: AppRegistryStore) {
        this._appRegistryStore = appRegistryStore;
    }

    get user(): UserModel | null {
        return this._user;
    }

    get token(): string | null {
        return this._token;
    }

    @computed get isLoggedIn() {
        return this._isLoggedIn;
    }
    // validate token in the memory in the backend
    private _isTokenValid(): Promise<boolean> {
        return Promise.resolve()
            .then(() => {
                if (this._token == null) return false;
                const url = this._appRegistryStore.mobileApiUrl + "/v2/EnvironmentName";
                return Axios.get<any, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._token } })
                    .then((res: AxiosResponse<any>) => {
                        return res.status === HttpStatus.OK;
                    })
                    .catch(async () => {
                        await this._resetAll();
                        return false;
                    });
            });
    }

    private _resetAll() {
        this._user = null;
        this._token = null;
        this._isLoggedIn = false;
        // remove
        return Promise.all([
            Storage.remove({ key: KEY_USER }),
            Storage.remove({ key: KEY_TOKEN })]);
    }

    // prevent errors that can't be managed by a restart
    private _cleanUp() {
        return this._resetAll()
            .catch((err) => {
                console.error(err);
            })
            .then(() => {
                throw Error("Unknown error");
            });
    }

    @action isValid(): Promise<boolean> {
        return Promise.resolve()
            .then(async () => {
                if (await this._isTokenValid()) {
                    this._isLoggedIn = true;
                    return true;
                }
                // load from storage

                const userObj = await Storage.get({ key: KEY_USER });
                if (userObj.value == null) return false;
                this._user = JSON.parse(userObj.value);

                const tokenObj = await Storage.get({ key: KEY_TOKEN });
                if (tokenObj.value == null) return false;
                this._token = JSON.parse(tokenObj.value);
                this._isLoggedIn = true;

                return this._isTokenValid();
            })
            .catch((e) => {
                return this._cleanUp();
            });
    }

    @action login(authentication: AuthenticationModel): Promise<LoginResult> {
        const url = this._appRegistryStore.mobileApiUrl + "/v3/Authenticate";
        return Axios.post<any, AxiosResponse<any>>(url, authentication)
            .then(async (res: AxiosResponse<any>) => {
                const userInfo = res.data;
                this._user = {
                    id: userInfo.Id,
                    userTypeId: userInfo.UserTypeId,
                    email: userInfo.Email,
                    firstName: userInfo.FirstName,
                    lastName: userInfo.LastName,
                    createDate: userInfo.CreateDate,
                    lastLogin: userInfo.LastLogin,
                    userStatusId: userInfo.UserStatusId,
                    notificationSvcAuthToken: userInfo.NotificationSvcAuthToken,
                    messageGroupCodes: userInfo.MessageGroupCodes
                }

                this._token = res.headers.accesstoken;
                this._isLoggedIn = true;

                await Storage.set({
                    key: KEY_USER,
                    value: JSON.stringify(this._user)
                });

                await Storage.set({
                    key: KEY_TOKEN,
                    value: JSON.stringify(this._token)
                });

                return {
                    isSuccessful: true,
                    message: undefined
                } as LoginResult;
            })
            .catch(async (err) => {
                if (err.response === undefined) {
                    return this._cleanUp();
                } else {
                    return {
                        isSuccessful: false,
                        message: err.response.Message
                    } as LoginResult;
                }
            });
    }

    @action logout() {
        return new Promise((res, rej) => {
                res();
        })
            .then(() => {
                this._user = null;
                this._token = null;
                this._isLoggedIn = false;

                Storage.remove({ key: KEY_USER });
                Storage.remove({ key: KEY_TOKEN });
            })
            .catch((e) => {
                console.log('logout', e);
                return this._cleanUp();
            });
    }

    isAdmin() {
        if(this._user?.userTypeId === 1 ||  this._user?.userTypeId === 2)
            return true;
        return false;
    }

    resetPassword(resetPassword: ResetPasswordModel) {
        const url = this._appRegistryStore.mobileApiUrl + "/v2/ResetPassword";
        return Axios.post<any, AxiosResponse<any>>(url, resetPassword)
            .then(async (res: AxiosResponse<any>) => {
                console.log('res',res);
            })
            .catch(async (err) => {
                console.log('err',err);
            });
    }
}