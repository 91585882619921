import { IonIcon, IonBadge, withIonLifeCycle } from '@ionic/react';
import React from 'react';
import AuthenticationStore from '../mobx/AuthenticationStore';
import { PATH_LOGIN,PATH_PRODUCTS, PATH_PROMOTIONS, PATH_INBOX, PATH_CUSTOMERS, PATH_CURRENT_STATUS, PATH_FEATURED_VENDORS, PATH_RANKING, PATH_USER_ACCOUNT, PATH_ABOUT, PATH_MY_SALES_REP } from '../common/Constants';

import { RouteComponentProps } from 'react-router';
// @ts-ignore
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '../theme/pages/sideMenu.css';
import AppStore from '../mobx/AppStore';
import MessageStore from '../mobx/MessageStore';

type State = {
	inboxCount: number,
    hasCount: boolean
}
interface Page {
    path: string;
}
interface Props extends RouteComponentProps {
    authenticationStore: AuthenticationStore;
	messageStore: MessageStore;
    appStore: AppStore;
}

class SideMenu extends React.Component<Props, State> {
    state: State = {
		inboxCount: 0,
        hasCount: false
    }		
    navigateToPage = (page: Page) => {
        this.props.history.replace(page.path);
    }

    logout = () => {
        this.props.authenticationStore.logout()
            .finally(() => {
                this.props.history.replace(PATH_LOGIN);
            });
    }

    componentDidMount() {
        this.props.messageStore.getUnreadMessagesCount().then(res=>{
            this.setState({inboxCount: res, hasCount: true});
        })
    }
    render() {
        return (
            <SideNav className={`${this.props.appStore.isWeb ? "web-nav" : ""}`}
                onSelect={(selected) => {
                    this.navigateToPage({path: selected})
                }}
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                    <NavItem eventKey="/home">
                        <NavIcon>
                            <IonIcon title="Belarus" className="gftg-icons home" src="./assets/svg/home-outline.svg" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Home
                        </NavText>
                    </NavItem>                    
                    <NavItem eventKey={PATH_PRODUCTS}>
                        <NavIcon>
                            <IonIcon title="test2" className="gftg-icons home" src="./assets/svg/r3.svg" style={{ fontSize: '1.75em' }} />

                        </NavIcon>
                        <NavText>
                            Products
                        </NavText>
                    </NavItem>
                    <NavItem eventKey={PATH_PROMOTIONS}>
                        <NavIcon>
                            <IonIcon className="gftg-icons home" src="./assets/svg/price.svg" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Promotions
                        </NavText>
                    </NavItem>
                    {this.state.hasCount &&
                    <NavItem eventKey={PATH_INBOX}>
                        <NavIcon>
                            <IonIcon className="gftg-icons home" src="./assets/svg/mail.svg" style={{ fontSize: '1.75em' }} />
                            {this.state.inboxCount > 0 && <IonBadge className="sidenav-badge" color="danger">{this.state.inboxCount}</IonBadge>}	
                        </NavIcon>
                        <NavText>
                            Inbox
                        </NavText>
                    </NavItem>
                    }
                    {this.props.authenticationStore.user?.userTypeId === 2 && 
                        <NavItem eventKey={PATH_CUSTOMERS}>
                            <NavIcon>
                                <IonIcon className="gftg-icons home" src="./assets/svg/customers.svg" style={{ fontSize: '1.75em' }} />
                            </NavIcon>
                            <NavText>
                                Customers
                            </NavText>
                        </NavItem>
                    }
                    {this.props.authenticationStore.user?.userTypeId === 3 && 
                        <NavItem eventKey={PATH_CURRENT_STATUS}>
                            <NavIcon>
                                <IonIcon className="gftg-icons home" src="./assets/svg/bars.svg" style={{ fontSize: '1.75em' }} />
                            </NavIcon>
                            <NavText>
                                Current Status
                            </NavText>
                        </NavItem>
                    }
                    <NavItem eventKey={PATH_FEATURED_VENDORS}>
                        <NavIcon>
                            <IonIcon className="gftg-icons home" src="./assets/svg/star.svg" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Featured Vendors
                        </NavText>
                    </NavItem>
                    {this.props.authenticationStore.user?.userTypeId === 2 && 
                        <NavItem eventKey={PATH_RANKING}>
                            <NavIcon>
                                <IonIcon className="gftg-icons home" src="./assets/svg/pin.svg" style={{ fontSize: '1.75em' }} />
                            </NavIcon>
                            <NavText>
                                Ranking List
                            </NavText>
                        </NavItem>
                    }
                    {this.props.authenticationStore.user?.userTypeId === 3 && 
                        <NavItem eventKey={PATH_MY_SALES_REP}>
                            <NavIcon>
                                <IonIcon className="gftg-icons home" src="./assets/svg/my-sales-rep.svg" style={{ fontSize: '1.75em' }} />
                            </NavIcon>
                            <NavText>
                                My Sales Rep
                            </NavText>
                        </NavItem>
                    }
                    {this.props.authenticationStore.user?.userTypeId !== 1 && 
                        <NavItem eventKey={PATH_USER_ACCOUNT}>
                            <NavIcon>
                                <IonIcon className="gftg-icons home" src="./assets/svg/id.svg" style={{ fontSize: '1.75em' }} />
                            </NavIcon>
                            <NavText>
                                Account
                            </NavText>
                        </NavItem>
                    }
                    <NavItem eventKey={PATH_ABOUT}>
                        <NavIcon name="about" title="about">
                            <IonIcon  name="about" title="about" className="gftg-icons home" src="./assets/svg/info.svg" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            About
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="logout" onClick={() => {this.logout()} }>
                        <NavIcon>
                            <IonIcon className="gftg-icons home" src="./assets/svg/logout.svg" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Logout
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        );
    }
};


// export default withIonLifeCycle(SideMenu);
export default SideMenu;


// export default withRouter(
//     SideMenu
// );