import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, withIonLifeCycle, IonContent, IonItem, IonLabel, isPlatform, getPlatforms } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import OtherContentStore from '../mobx/OtherContentStore';
/* Custom CSS */
import '../theme/pages/about.css';
import { arrowBack } from 'ionicons/icons';
import Loading from '../components/Loading';
import AppStore from '../mobx/AppStore';
import { PATH_LOGIN } from '../common/Constants';
import ENV from '../env/env';
// import ReactDOM from 'react-dom';

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	otherContentStore: OtherContentStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean,
	isError: boolean,
	showTab: string
}


type Member = {
	isMounted: boolean
}

class About extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true,
		isError: false,
		showTab:"details"
	}

	member: Member = {
		isMounted: false
	}
	
	ionViewDidEnter() {
		this.member.isMounted = true;
		this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						this.setState({ isHidden: false, isLoading: false });

					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
		console.log("gftg: " + getPlatforms());
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}
	getLinkDesktop(goTo:string) {
		this.props.otherContentStore.fetch(goTo)
		.then((doc:any) => {
			//@ts-ignore
			document.getElementById('container2').innerHTML = "";
			document.getElementById('container2')?.append(doc);
		})
		.catch((e) => {
			this.setState({ isHidden: false, isLoading: false, isError: true });
		});
	}
	showLinkDesktop(goTo:string) {
		this.setState({ showTab: goTo });
	}
	goLinkInBrowser = (goTo:string) => {
		debugger;
			console.log('goLinkInBrowser goTo', goTo);
		this.setState({isLoading: true});
		let inAppBrowserRef;
		const _this = this;
		function loadStopCallBack() {
			if (inAppBrowserRef !== undefined) {
				inAppBrowserRef.insertCSS({ code: ".fusion-tb-header {display: none;}" });
				inAppBrowserRef.show();
			}
			_this.setState({isLoading: false});

		}
        const url = ENV.REACT_APP_SITE_CORE_URL +'/'+goTo+'/';
		const target = "_blank";
		let options = "hideurlbar=yes,hidenavigationbuttons=yes,hidden=yes,beforeload=yes";
		if(isPlatform("ios")||isPlatform("ipad")){
			options = "location=no,hidenavigationbuttons=yes";
		}
		console.log('url', url);
		//@ts-ignore
		inAppBrowserRef = cordova.InAppBrowser.open(url, target, options);
		inAppBrowserRef.addEventListener('loadstop', loadStopCallBack);
	}
	
	render() {
		const newElement = document.createElement('span');
		newElement.innerText = 'portal element';
		return (

			<IonPage className="about">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={ () => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								<IonTitle>About</IonTitle>
							</IonToolbar>
							<div className="curve-static"></div>
						</IonHeader>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='header-toolbar'>
								<>
									<IonTitle  className="toolbar-title" >About</IonTitle>
								</>
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
				this.state.isError ?
					<IonContent id="test">
						<IonItem className="message-top" lines="none">
							<IonLabel className="terms-error">
								There was an error trying to load the about page.
							</IonLabel>
						</IonItem>
						<IonItem className="message-top" lines="none">
							<IonLabel className="terms-alternative">
								Please read the page <a href="https://www.r3goforthegold.com/details" target="_blank">Here</a>
							</IonLabel>
						</IonItem>
					</IonContent>
					:
					<>

						{!isPlatform("mobileweb") && (isPlatform("android") || isPlatform("ios")) ?
						<>
							<IonButtons slot="primary" className="about-off-links">
								<IonButton color="primary" onClick={ () => { this.goLinkInBrowser('details'); }}>DETAILS</IonButton>
								<IonButton color="primary" onClick={ () => { this.goLinkInBrowser('program'); }}>PROGRAM</IonButton>
								<IonButton color="primary" onClick={ () => { this.goLinkInBrowser('destination'); }}>DESTINATION</IonButton>
							</IonButtons>
							<IonContent id="test">
								<div></div>
							</IonContent>
						</>
						:	
						<>
							<IonButtons slot="primary" className="about-off-links">
								{/* <IonButton color="primary" onClick={ () => { this.getLinkDesktop('details'); }}>DETAILS</IonButton>
								<IonButton color="primary" onClick={ () => { this.getLinkDesktop('program'); }}>PROGRAM</IonButton>
								<IonButton color="primary" onClick={ () => { this.getLinkDesktop('destination'); }}>DESTINATION</IonButton> */}
								<IonButton color="primary" onClick={ () => { this.showLinkDesktop('details'); }}>DETAILS</IonButton>
								<IonButton color="primary" onClick={ () => { this.showLinkDesktop('program'); }}>PROGRAM</IonButton>
								<IonButton color="primary" onClick={ () => { this.showLinkDesktop('destination'); }}>DESTINATION</IonButton>
							</IonButtons>
							<IonContent id="test">
							{(this.state.showTab == 'details' || this.state.showTab == 'program' || this.state.showTab == 'destination') &&
								<div id="frameContainer"><iframe src={`${ENV.REACT_APP_SITE_CORE_URL}/${this.state.showTab}/`} width="100%" height="95%" id="myIframe"></iframe></div>
							}
							</IonContent>
						</>
						}
					</>
					}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(About);
