import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonRow, IonGrid, withIonLifeCycle, IonIcon, IonBadge, IonImg, IonToast, IonButtons } from '@ionic/react';
import React from 'react';
import { Plugins, PluginListenerHandle } from '@capacitor/core';
import AuthenticationStore from '../mobx/AuthenticationStore';
import { RouteComponentProps } from 'react-router';
import Loading from '../components/Loading';
import Copyright from '../components/Copyright';
/* Custom CSS */
import '../theme/pages/home.css';
import { PATH_LOGIN, PATH_PRODUCTS, PATH_PROMOTIONS, PATH_INBOX, PATH_CUSTOMERS, PATH_CURRENT_STATUS, PATH_FEATURED_VENDORS, PATH_RANKING, PATH_USER_ACCOUNT, PATH_ABOUT, PATH_MY_SALES_REP} from '../common/Constants';
import { arrowForward } from 'ionicons/icons';
import PushNotificationStore from '../mobx/PushNotificationStore';
import SalesDataStore from '../mobx/SalesDataStore';
import { observer } from 'mobx-react';
import AppStore from '../mobx/AppStore';
import MessageStore from '../mobx/MessageStore';
import Goals from './Goals';

const { App } = Plugins;

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	pushNotificationStore: PushNotificationStore;
	salesDataStore: SalesDataStore;
	messageStore: MessageStore;
	appStore: AppStore;
}

type State = {
	isLoading: boolean,
	isToogleMenu: boolean,
	isCustomer: boolean,
	inboxCount: number,
	hasCount: boolean
}

type Member = {
	isMounted: boolean,
	backButtonHandle: PluginListenerHandle | null
}

@observer
class Home extends React.Component<Props, State> {

	state: State = {
		isLoading: true,
		isToogleMenu: true,
		isCustomer: false,
		inboxCount: 0,
		hasCount: false
	}

	member: Member = {
		isMounted: false,
		backButtonHandle: null
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		this.member.backButtonHandle = App.addListener("backButton", () => {
			// really empty
		});
		this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						if(this.props.authenticationStore.user?.userTypeId == 3)
							this.setState({isCustomer: true});
						this.props.pushNotificationStore.initPushNotification()
							.then(() => {
								return this.props.salesDataStore.fetch();
							})
							.finally(() => {
								this.setState({isLoading: false});
								this.props.messageStore.getUnreadMessagesCount().then(res=>{
									this.setState({ inboxCount: this.props.messageStore.getMessageCount, hasCount: true});
								})
								
								
							});
					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
		this.member.backButtonHandle!.remove();
		this.props.pushNotificationStore.setPushRedirectTo(null);
	}

	logout = () => {
		this.setState({isLoading: true});
		this.props.authenticationStore.logout()
			.finally(() => {
				this.setState({isLoading: false});
				this.props.history.replace(PATH_LOGIN);
			});
	}
	
	toggleMenu = () => {
		if(this.state.isToogleMenu)
			this.setState({isToogleMenu: false});
		else 
			this.setState({isToogleMenu: true});
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		if (this.props.pushNotificationStore.pushRedirectTo) {
			const to = this.props.pushNotificationStore.pushRedirectTo;
			if (this.props.messageStore.messages.length !== 0 && this.props.history.location.pathname !== to) {
				this.props.history.push(to);
			}
		}
	}
	
	render() {		
		return (
			<IonPage className="home">
				<div className='hidden'>{this.props.pushNotificationStore.pushRedirectTo}</div>
				<div className='hidden'>{this.props.messageStore.messages.length}</div>
				{!this.props.appStore.isWeb &&
					<IonHeader>
						<IonToolbar mode="md">
							{this.state.isCustomer &&
								<IonButtons slot="start">
									<button onClick={() => { this.toggleMenu(); }} role="button" className="sidenav---sidenav-toggle---1KRjR" aria-expanded="false">
										<span className="sidenav---icon-bar---u1f02"></span>
										<span className="sidenav---icon-bar---u1f02"></span>
										<span className="sidenav---icon-bar---u1f02"></span>
									</button>
								</IonButtons>
							}

							<IonGrid  class="ion-text-center">
								{!this.props.authenticationStore.isAdmin() && <IonRow><IonTitle className="points">{this.props.salesDataStore.salesData?.ytdPoints} pts</IonTitle></IonRow>}
								<IonRow><IonTitle className="name">{this.props.authenticationStore.user?.firstName + " " + this.props.authenticationStore.user?.lastName}</IonTitle></IonRow>
								<IonRow><IonTitle className="email">{this.props.authenticationStore.user?.email}</IonTitle></IonRow>
							</IonGrid>
						</IonToolbar>
						<div className="toolbar-curve"></div>
					</IonHeader>
				}
				{!this.props.appStore.isWeb ?
					<IonContent className="ion-padding home-content">
						{this.state.isCustomer ?
							this.state.isToogleMenu ?
								<>
									<Goals salesDateStore={this.props.salesDataStore} />
									<Copyright />
									<IonToast
										isOpen={this.props.pushNotificationStore.showToastPush}
										onDidDismiss={() => this.props.pushNotificationStore.setShowToastPush(false)}
										message="You have a new message."
										duration={2000}
									/>
								</>
							:
								<>
									<IonItem lines="none" detail={false} routerLink={PATH_PRODUCTS}><IonIcon className="gftg-icons home" src="./assets/svg/r3.svg" /><IonLabel>Products</IonLabel><IonIcon icon={arrowForward.ios} /></IonItem>
									<IonItem lines="none" detail={false} routerLink={PATH_PROMOTIONS}><IonIcon className="gftg-icons home" src="./assets/svg/price.svg" /><IonLabel>Promotions</IonLabel><IonIcon icon={arrowForward.ios} /></IonItem>
									{this.state.hasCount && 
										<IonItem lines="none" detail={false} routerLink={PATH_INBOX} >
											<IonIcon className="gftg-icons home" src="./assets/svg/mail.svg" />
											{this.state.inboxCount > 0 && <IonBadge color="danger">{this.state.inboxCount}</IonBadge>}	
											<IonLabel>Inbox</IonLabel><IonIcon icon={arrowForward.ios} />
										</IonItem>
									}
									{this.props.authenticationStore.user?.userTypeId === 2 && 
										<IonItem lines="none" detail={false} routerLink={PATH_CUSTOMERS}>
											<IonIcon className="gftg-icons home" src="./assets/svg/customers.svg" />
											<IonLabel>Customers</IonLabel><IonIcon icon={arrowForward.ios} />
										</IonItem>
									}
									{this.props.authenticationStore.user?.userTypeId === 3 && 
										<IonItem lines="none" detail={false} routerLink={PATH_CURRENT_STATUS}>
											<IonIcon className="gftg-icons home" src="./assets/svg/bars.svg" />
											<IonLabel>Current Status</IonLabel><IonIcon icon={arrowForward.ios} />
										</IonItem>
									}
									<IonItem lines="none" detail={false} routerLink={PATH_FEATURED_VENDORS}><IonIcon className="gftg-icons home" src="./assets/svg/star.svg" /><IonLabel>Featured Vendors</IonLabel><IonIcon icon={arrowForward.ios} /></IonItem>
									{this.props.authenticationStore.user?.userTypeId === 2 && 
										<IonItem lines="none" detail={false} routerLink={PATH_RANKING}>
											<IonIcon className="gftg-icons home" src="./assets/svg/pin.svg" />
											<IonLabel>Ranking List</IonLabel><IonIcon icon={arrowForward.ios} />
										</IonItem>
									}
									{this.props.authenticationStore.user?.userTypeId === 3 && 
										<IonItem lines="none" detail={false} routerLink={PATH_MY_SALES_REP}>
											<IonIcon className="gftg-icons home" src="./assets/svg/my-sales-rep.svg" />
											<IonLabel>My Sales Rep</IonLabel><IonIcon icon={arrowForward.ios} />
										</IonItem>
									}
									{this.props.authenticationStore.user?.userTypeId !== 1 && 
										<IonItem lines="none" detail={false} routerLink={PATH_USER_ACCOUNT}>
											<IonIcon className="gftg-icons home" src="./assets/svg/id.svg" />
											<IonLabel>Account</IonLabel><IonIcon icon={arrowForward.ios} />
										</IonItem>
									}
									<IonItem lines="none" detail={false} routerLink={PATH_ABOUT}><IonIcon className="gftg-icons home" src="./assets/svg/info.svg" />
										<IonLabel>About</IonLabel><IonIcon icon={arrowForward.ios} />
									</IonItem>
									<IonItem lines="none" detail={false} onClick={() => {this.logout()}} button={true}><IonIcon className="gftg-icons home" src="./assets/svg/logout.svg" /><IonLabel>Logout</IonLabel><IonIcon icon={arrowForward.ios} /></IonItem>
								
									<IonItem className="powered-item" lines="none">
										<IonLabel>Powered by &nbsp;</IonLabel>
										<img src="./assets/img/white_r3_logo.png" alt="" />
									</IonItem>
									<Copyright />
									<IonToast
										isOpen={this.props.pushNotificationStore.showToastPush}
										onDidDismiss={() => this.props.pushNotificationStore.setShowToastPush(false)}
										message="You have a new message."
										duration={2000}
									/>
								</>
						:
						<>
							<IonItem lines="none" detail={false} routerLink={PATH_PRODUCTS}><IonIcon className="gftg-icons home" src="./assets/svg/r3.svg" /><IonLabel>Products</IonLabel><IonIcon icon={arrowForward.ios} /></IonItem>
							<IonItem lines="none" detail={false} routerLink={PATH_PROMOTIONS}><IonIcon className="gftg-icons home" src="./assets/svg/price.svg" /><IonLabel>Promotions</IonLabel><IonIcon icon={arrowForward.ios} /></IonItem>
							{this.state.hasCount && <IonItem lines="none" detail={false} routerLink={PATH_INBOX}>
								<IonIcon className="gftg-icons home" src="./assets/svg/mail.svg" />
								{this.state.inboxCount > 0 && <IonBadge color="danger">{this.state.inboxCount}</IonBadge>}	
								<IonLabel>Inbox</IonLabel><IonIcon icon={arrowForward.ios} />
							</IonItem>}
							{this.props.authenticationStore.user?.userTypeId === 2 && 
								<IonItem lines="none" detail={false} routerLink={PATH_CUSTOMERS}>
									<IonIcon className="gftg-icons home" src="./assets/svg/customers.svg" />
									<IonLabel>Customers</IonLabel><IonIcon icon={arrowForward.ios} />
								</IonItem>
							}
							{this.props.authenticationStore.user?.userTypeId === 3 && 
								<IonItem lines="none" detail={false} routerLink={PATH_CURRENT_STATUS}>
									<IonIcon className="gftg-icons home" src="./assets/svg/bars.svg" />
									<IonLabel>Current Status</IonLabel><IonIcon icon={arrowForward.ios} />
								</IonItem>
							}
							<IonItem lines="none" detail={false} routerLink={PATH_FEATURED_VENDORS}><IonIcon className="gftg-icons home" src="./assets/svg/star.svg" /><IonLabel>Featured Vendors</IonLabel><IonIcon icon={arrowForward.ios} /></IonItem>
							{this.props.authenticationStore.user?.userTypeId === 2 && 
								<IonItem lines="none" detail={false} routerLink={PATH_RANKING}>
									<IonIcon className="gftg-icons home" src="./assets/svg/pin.svg" />
									<IonLabel>Ranking List</IonLabel><IonIcon icon={arrowForward.ios} />
								</IonItem>
							}
							{this.props.authenticationStore.user?.userTypeId === 3 && 
								<IonItem lines="none" detail={false} routerLink={PATH_MY_SALES_REP}>
									<IonIcon className="gftg-icons home" src="./assets/svg/my-sales-rep.svg" />
									<IonLabel>My Sales Rep</IonLabel><IonIcon icon={arrowForward.ios} />
								</IonItem>
							}
							{this.props.authenticationStore.user?.userTypeId !== 1 && 
								<IonItem lines="none" detail={false} routerLink={PATH_USER_ACCOUNT}>
									<IonIcon className="gftg-icons home" src="./assets/svg/id.svg" />
									<IonLabel>Account</IonLabel><IonIcon icon={arrowForward.ios} />
								</IonItem>
							}
							<IonItem lines="none" detail={false} routerLink={PATH_ABOUT}><IonIcon className="gftg-icons home" src="./assets/svg/info.svg" />
								<IonLabel>About</IonLabel><IonIcon icon={arrowForward.ios} />
							</IonItem>
							<IonItem lines="none" detail={false} onClick={() => {this.logout()}} button={true}><IonIcon className="gftg-icons home" src="./assets/svg/logout.svg" /><IonLabel>Logout</IonLabel><IonIcon icon={arrowForward.ios} /></IonItem>
						
							<IonItem className="powered-item" lines="none">
								<IonLabel>Powered by &nbsp;</IonLabel>
								<img src="./assets/img/white_r3_logo.png" alt="" />
							</IonItem>
							<Copyright />
							<IonToast
								isOpen={this.props.pushNotificationStore.showToastPush}
								onDidDismiss={() => this.props.pushNotificationStore.setShowToastPush(false)}
								message="You have a new message."
								duration={2000}
							/>
						</>
						}
					</IonContent>
					:
					<IonContent className="ion-padding home-content web">
						<IonLabel className="center-text">Welcome</IonLabel>
						{this.state.isCustomer ?
							<Goals salesDateStore={this.props.salesDataStore} />
						:
							<>
								<video className="videogftg" src="./assets/video/Gold_Landscape.mp4" autoPlay loop muted></video>
								<IonImg className="gftg-logo" src={"./assets/r3-icons/G4G_Logo_FINAL_White.svg"} />
							</>
						}
						<Copyright />
						<IonToast
							isOpen={this.props.pushNotificationStore.showToastPush}
							onDidDismiss={() => this.props.pushNotificationStore.setShowToastPush(false)}
							message="You have a new message."
							duration={2000}
						/>
					</IonContent>
				}
				
				{this.state.isLoading && <Loading />}
			</IonPage>
		);
	}
}

export default withIonLifeCycle(Home);
