import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, withIonLifeCycle, IonText, IonButtons, isPlatform, IonButton, IonIcon} from '@ionic/react';
import React from 'react';
import Promotion from '../components/Promotion';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import { observer } from 'mobx-react';
import PromotionsStore from '../mobx/PromotionsStore';
import PromotionModel from '../models/PromotionModel';
import { arrowBack} from 'ionicons/icons';
import { PATH_LOGIN } from '../common/Constants';
/* Custom CSS */
import '../theme/pages/promotions.css';
import AppStore from '../mobx/AppStore';

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	promotionsStore: PromotionsStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean,
	isFiltersOpen: boolean,
	isDesc: boolean
}

type Member = {
	isMounted: boolean
}

@observer
class Promotions extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true,
		isFiltersOpen: false,
		isDesc: false
	}

	member: Member = {
		isMounted: false
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						this.setState({ isHidden: false });
						this.props.promotionsStore.fetch()
							.finally(() => {
								this.setState({ isLoading: false });
							});
					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	toggleIsDesc = () => {
		this.setState({ isDesc: !this.state.isDesc });
	}

	

	render() {
		return (
			<IonPage className="promotions" >
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={() => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								<IonTitle  className="toolbar-title" >Promotions</IonTitle>
							</IonToolbar>
							<div className="curve-static"></div>
						</IonHeader>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='header-toolbar'>
								<IonTitle  className="toolbar-title" >Promotions</IonTitle>
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
					<>
						<IonContent className="content-main" id="content-main">
							{
								this.props.promotionsStore.promotions.length === 0 ?
									<IonText class="text-center">No Promotions</IonText> :
									<IonList className="promotion-list">
										{this.props.promotionsStore.promotions.map((it: PromotionModel) => {
											return <Promotion key={it.id} promotion={it}></Promotion>
										})}
									</IonList>
							}
						</IonContent>
					</>}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(Promotions);
