import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonContent, IonGrid, IonRow, IonCol, IonItem, IonInput, IonButton, withIonLifeCycle, IonIcon, IonLabel } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import MySalesRepStore from '../mobx/MySalesRepStore';
import Loading from '../components/Loading';
import { arrowBack } from 'ionicons/icons';
import { PATH_LOGIN, PATH_HOME } from '../common/Constants';
import '../theme/pages/mySalesRep.css';
import AppStore from '../mobx/AppStore';
import UserModel from '../models/UserModel';
interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	mySalesRepStore: MySalesRepStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean,
	salesRep: UserModel|null
}

type Member = {
	isMounted: boolean
}

class MySalesRep extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true,
		salesRep:null
	}

	member: Member = {
		isMounted: false
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		const _this = this;
		this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						_this.props.mySalesRepStore.get().then((userInfo: any) => {
							
							// const user = {
							// 	id: 111,
							// 	userTypeId: 11,
							// 	email: 'aerkz@gate3.co',
							// 	firstName: 'arek',
							// 	lastName: 'Zwolenik',
							// 	createDate: "",
							// 	lastLogin: "",
							// 	userStatusId: 0,
							// 	notificationSvcAuthToken: "",
							// 	messageGroupCodes: [""]
							// }
							// this.setState({ isHidden: false, isLoading: false, salesRep: user });
							if(userInfo !== null) {
								const user = {
									id: userInfo.Id,
									userTypeId: userInfo.UserTypeId,
									email: userInfo.Email,
									firstName: userInfo.FirstName,
									lastName: userInfo.LastName,
									createDate: userInfo.CreateDate,
									lastLogin: userInfo.LastLogin,
									userStatusId: userInfo.UserStatusId,
									notificationSvcAuthToken: userInfo.NotificationSvcAuthToken,
									messageGroupCodes: userInfo.MessageGroupCodes
								}
								this.setState({ isHidden: false, isLoading: false, salesRep: user });
							} else {
								this.setState({ isHidden: false, isLoading: false});
							}
						})
					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	mail(){
        window.open(`mailto:${this.state.salesRep?.email}`, '_system')
	}
	render() {
		return (
			<IonPage className="my-sales-rep">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={() => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								<IonTitle  className="toolbar-title" >My Sales Rep</IonTitle>
							</IonToolbar>
							<div className="curve-static"></div>
						</IonHeader>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='header-toolbar'>
									<>
										<IonTitle  className="toolbar-title" >My Sales Rep</IonTitle>
									</> 
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
					<>
						<IonContent>
							{this.state.salesRep ?
								<>
									<IonRow>
										<IonCol col-12>
											<span className="sales-rep-name">
												{ this.state.salesRep?.firstName } { this.state.salesRep?.lastName }
											</span>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol col-12 >
											<div className="email">
												{ this.state.salesRep?.email }
											</div>
											<IonButton onClick={() => { this.mail(); }}>Email</IonButton>
										</IonCol>
									</IonRow>
								</>
							:
								<IonRow>
									<IonCol>
										<span className="no-sales-rep">
											We are unable to locate a sales representative for your account.
											<br />Please contact your local branch.
										</span>
									</IonCol>
								</IonRow>
							}
						</IonContent>
					</>
				}
				{this.state.isLoading && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(MySalesRep);