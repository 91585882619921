import { IonText } from "@ionic/react";
import React, { Component } from "react";
import {
	BarChart,
	ResponsiveContainer,
	XAxis,
	Bar,
	YAxis,
	ReferenceLine,
	LabelList,
	AxisDomain,
} from "recharts";
interface Props {
	YearOverYearGrowth: number;
}
export default class YearlyGrowthBar extends Component<Props> {
	private get _rechartsData() {
		return [
			{
				name: "Growth",
				value: this.props.YearOverYearGrowth,
			},
		];
	}

	private get _axisDomain(): [AxisDomain, AxisDomain] {
		const absNumber = Math.abs(this.props.YearOverYearGrowth);
		const roundedNumber = Math.ceil(absNumber / 10) * 10;
		return [-roundedNumber, roundedNumber];
	}

	private get _barColor() {
		if (this.props.YearOverYearGrowth > 0) {
			return "#318c55";
		} else {
			return "#b33838";
		}
	}

	private get _labelOffset() {
		if (this.props.YearOverYearGrowth > 0) {
			return 5;
		} else {
			return -35;
		}
	}

	render() {
		return (
			<div className="yearly-growth-bar">
				<div style={{ width: "100%", height: "80px" }}>
					<ResponsiveContainer width="100%" height="100%">
						<BarChart layout="vertical" data={this._rechartsData}>
							<YAxis type="category" hide />
							<XAxis
								type="number"
								domain={this._axisDomain}
								stroke="#fff"
								tickFormatter={(tick) => tick + "%"}
							/>
							<ReferenceLine x={0} stroke="#fff" />
							<Bar dataKey="value" barSize={20} fill={this._barColor}>
								<LabelList
									dataKey={"value"}
									position="insideRight"
									className="yoy-label"
									offset={this._labelOffset}
									formatter={(value) => value + "%"}
								/>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
				<IonText className="bar-name">Yearly Growth</IonText>
			</div>
		);
	}
}
