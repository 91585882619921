import { observable, action, computed } from "mobx";
import RankingModel from "../models/RankingModel";
import Axios, { AxiosResponse } from "axios";
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";
import { RankingSortTypes } from "../enums/RankingSortTypes";

export default class RankingListStore {
	private _appRegistryStore: AppRegistryStore;
	private _authenticationStore: AuthenticationStore;
	private _rankingListInternal: Array<RankingModel> = [];
	private _rankingListInternalHeaders: Array<string> = [];
	@observable private _rankingList: Array<RankingModel> = [];

	constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
		this._appRegistryStore = appRegistryStore;
		this._authenticationStore = authenticationStore;
	}

	@computed get rankingList() {
		return this._rankingList;
	}

	@computed get rankingListHeaders() {
		return this._rankingListInternalHeaders;
	}

	@action fetch() {
		this._rankingListInternal = [];
		this._rankingList = [];
		const url = this._appRegistryStore.mobileApiUrl + "/v2/SalesRep/Ranking";
		return Axios.get<any, AxiosResponse<any>>(url, {
			headers: { Authorization: "Bearer " + this._authenticationStore.token },
		}).then((res: AxiosResponse<any>) => {
			this._rankingListInternal = res.data.map((resRow) => {
				// first and second values should always be rank and name
				const values = Object.values(resRow);
				return {
					rank: Number(values[0]),
					name: values[1],
					...resRow,
				};
			});
			// get header fields withous the first two
			this._rankingListInternalHeaders = Object.keys(res.data[0]).filter(
				(_, i) => i !== 0 && i !== 1,
			);
		});
	}

	@action load(sortBy: RankingSortTypes, isDesc: boolean, count: number, shouldReset: boolean) {
		if (shouldReset) {
			this._rankingList = [];
			const compare = (a: RankingModel, b: RankingModel) => {
				switch (sortBy) {
					case RankingSortTypes.Rank: {
						if (a.rank < b.rank) {
							return -1;
						} else if (a.rank > b.rank) {
							return 1;
						} else {
							return 0;
						}
					}
					case RankingSortTypes.Name: {
						if (a.name < b.name) {
							return -1;
						} else if (a.name > b.name) {
							return 1;
						} else {
							return 0;
						}
					}
				}
			};

			this._rankingListInternal.sort((a, b) => {
				return compare(a, b) * (isDesc ? -1 : 1);
			});
		}

		const maxLength = Math.min(this._rankingList.length + count, this._rankingListInternal.length);
		for (let i = this._rankingList.length; i < maxLength; ++i) {
			this._rankingList.push(this._rankingListInternal[i]);
		}

		// has more data
		return this._rankingList.length !== this._rankingListInternal.length;
	}

	@action getRanking(rank: number): RankingModel | undefined {
		return this._rankingListInternal.find((ranking) => ranking.rank === rank);
	}
}
