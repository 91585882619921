import React, { Component } from "react";
import { getformatNumber } from "../../common/utils";
import "./progress-bar.css";

interface Props {
	value: number;
	target: number;
	prefix?: string;
	suffix?: string;
}
export default class ProgressBar extends Component<Props> {
	private _ref: React.RefObject<HTMLDivElement> = React.createRef();
	getStyle(): React.CSSProperties {
		const currentWidth = this._ref.current?.clientWidth ?? 0;

		return {
			width: `${(this.props.value / this.props.target) * 100}%`,
			justifyContent: currentWidth > 80 ? "flex-end" : "flex-start",
		};
	}
	render() {
		return (
			<div className="progress-bar">
				<div className="bar">
					<div className="progress" style={this.getStyle()} ref={this._ref}>
						<div className="value">
							{this.props.prefix}
							{getformatNumber(this.props.value, 0)}
							{this.props.suffix}
						</div>
					</div>
				</div>
				<div className="target">
					{this.props.prefix}
					{getformatNumber(this.props.target, 0)}
					{this.props.suffix}
				</div>
			</div>
		);
	}
}
