import { withIonLifeCycle, IonToast, IonPage, IonToolbar, IonTitle, IonButtons, IonContent, IonItem, IonButton, IonLabel, IonInput, IonCheckbox, IonIcon, IonModal, IonImg, IonList, IonSelect, IonSelectOption, isPlatform } from '@ionic/react';
import React, { FormEvent } from 'react';
import AuthenticationStore from '../mobx/AuthenticationStore';
import OtherContentStore from '../mobx/OtherContentStore';
import { RouteComponentProps } from 'react-router';
import { arrowBack } from 'ionicons/icons';
/* Custom CSS */
import '../theme/pages/registration.css';
import RegistrationStore from '../mobx/RegistrationStore';
import AppStore from '../mobx/AppStore';
import { Link } from 'react-router-dom';
import ENV from "../env/env";
import LoadingInside from '../components/LoadingInside';

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	registrationStore: RegistrationStore;
	otherContentStore: OtherContentStore;
	appStore: AppStore;
}
type State = {
	isHidden: boolean,
	isLoading: boolean,
	isTermsOpen: boolean,
	isPolicyOpen: boolean,
	isSurveyOpen: boolean,
	email: string,
	password: string,
	confirmPassword: string,
	firstName: string,
	lastName: string,
	address1: string,
	address2: string,
	city: string,
	stateId: number,
	zipCode: string,
	phone: string,
	industryId: number,
	branchNumber: number,
	customerNumber: number,
	agreedToTermsOfUse: boolean,
	showToast: boolean,
	toastMsg: string,
	pp: string,
	isSuccess: boolean,
	isDisabled: boolean,
	showAccountMsg: boolean
}

type Member = {
	isMounted: boolean
}
const customAlertOptions = {
	header: 'Select State',
	// subHeader: 'Select your toppings',
	// message: '$1.00 per topping',
	// translucent: true
};

class Registration extends React.Component<Props, State> {
	
	state: State = {
		isHidden: true,
		isLoading: false,
		isTermsOpen: false,
		isPolicyOpen: false,
		isSurveyOpen: false,
		email: "",
		password: "",
		confirmPassword: "",
		firstName: "",
		lastName: "",
		address1: "",
		address2: "",
		city: "",
		stateId: 0,
		zipCode: "",
		phone: "",
		industryId: 0,
		branchNumber: 0,
		customerNumber: 0,
		agreedToTermsOfUse: false,
		showToast: false,
		toastMsg: "",
		pp: "",
		isSuccess: false,
		isDisabled: true,
		showAccountMsg: false
	}

	member: Member = {
		isMounted: false
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		if (this.member.isMounted) {
			this.setState({ isHidden: false });
			this.props.registrationStore.fetch()
				.finally(() => {
					this.setState({ isLoading: false });
				});
		}
	}
	async handleSubmit(e: FormEvent) {
		e.preventDefault();

		try {
			//INDIANAPOLIS
			//1047
			if(!this.state.agreedToTermsOfUse) { 
				this.setShowToast(true,  "Approve to Terms of Use & Privacy Policy");
			}
			
			// this.props.registrationStore.registration.Phone = this.state.phone;
			else if(Number.isNaN(this.state.customerNumber) || this.state.customerNumber === 0 ) { 
				this.setShowToast(true,  "Account Number is required.");
			}
			else if(Number.isNaN(this.state.branchNumber) || this.state.branchNumber === 0 ) {
				this.setShowToast(true,  "Branch Number");
			}
			else if(Number.isNaN(this.state.industryId) || this.state.industryId === 0 ) {
				this.setShowToast(true,  "Industry is required.");
			}
			else if(Number.isNaN(this.state.stateId) || this.state.stateId === 0 ) {
				this.setShowToast(true,  "State is required.");
			}
			else if(this.state.zipCode.length < 5 || this.state.zipCode.length > 10) { 
				this.setShowToast(true,  "Zip Code must be between 5 and 10 characters. Zip Code is invalid.");
			}
			else if(!(this.state.city.length > 0)) { 
				this.setShowToast(true,  "City is required.");
			}
			else if(!(this.state.address1.length > 0)) { 
				this.setShowToast(true,  "Address is required.");
			}
			else if(!(this.state.firstName.length > 0)) { 
				this.setShowToast(true,  "First Name is required.");
			}
			else if(!(this.state.lastName.length > 0)) { 
				this.setShowToast(true,  "Last Name is required.");
			}
			else if(this.state.phone.length !== 7 && this.state.phone.length !== 10 && this.state.phone.length !== 11 ) { 
				this.setShowToast(true,  "Phone Number is invalid.");
			}
			else {

				this.setState({ isLoading: true, isDisabled: true });

				this.props.registrationStore.registration.Password = this.state.password;
				this.props.registrationStore.registration.ConfirmPassword = this.state.confirmPassword;
				this.props.registrationStore.registration.Email = this.state.email;
				this.props.registrationStore.registration.FirstName = this.state.firstName;
				this.props.registrationStore.registration.LastName = this.state.lastName;
				this.props.registrationStore.registration.Address1 = this.state.address1;
				this.props.registrationStore.registration.Address2 = this.state.address2;
				this.props.registrationStore.registration.City = this.state.city;
				this.props.registrationStore.registration.StateId = this.state.stateId;
				this.props.registrationStore.registration.ZipCode = this.state.zipCode;
				this.props.registrationStore.registration.Phone = this.state.phone;
				this.props.registrationStore.registration.IndustryId = this.state.industryId;
				this.props.registrationStore.registration.BranchNumber = this.state.branchNumber;
				this.props.registrationStore.registration.CustomerNumber = this.state.customerNumber;
				this.props.registrationStore.registration.AgreedToTermsOfUse = this.state.agreedToTermsOfUse;
				// console.log('test reg', this.props.registrationStore.registration);
				this.props.registrationStore.register()
					.then((res:any) => {
						this.state.toastMsg = ""
						if (res.success) {
							this.setState({ isSuccess: true });
						} else {
							this.setShowToast(true, res.msg.join('\r\n'));
						}
					})
					.finally(() => {
						this.setState({ isLoading: false, isDisabled: false });
					});
			}
		} catch (e) {
			console.error(e);
		}
	}
	setShowTerms = (change: boolean) => {
		this.setState({ isTermsOpen: change });
	}
	setShowPolicy = (change: boolean) => {
		this.setState({ isPolicyOpen: change });
	}
	setShowSurvey = (change: boolean) => {
		this.setState({ isSurveyOpen: change });
	}
	setShowToast = (change: boolean, msg: string) => {
		this.setState({ showToast: change, toastMsg: msg });
	}
	tosLinkInBrowser = () => {
        const url = ENV.REACT_APP_SITE_CORE_URL +'/terms-of-use';
		//@ts-ignore
		cordova.InAppBrowser.open(url, '_system', 'location=no');
	}
	ppLinkInBrowser = () => {
        const url = ENV.REACT_APP_SITE_CORE_URL +'/privacy-policy';
		//@ts-ignore
		cordova.InAppBrowser.open(url, '_system', 'location=no');
	}
	onChange = (e: any) => {
		//input requier a name
		// @ts-ignore
		this.setState({ [e.target.name]: e.target.value });
	}

	onSelectChange = (e: any) => {
		// @ts-ignore
		this.setState({ [e.target.name]: e.target.value.Id });		
		if(e.target.name === 'branchNumber'){
			this.checkAccountNumber();
		}
	}

	onChangeNumber = (e: any) => {
		const newVal = parseInt(e.target.value)
		// @ts-ignore
		this.setState({ [e.target.name]: newVal });
		if(e.target.name === 'customerNumber'){
			this.checkAccountNumber();
		}
	}
	setChecked = (val:boolean) => {
		this.setState({agreedToTermsOfUse: val});
	}
	
	checkAccountNumber(){
		this.setState({ isDisabled: true, showAccountMsg: false});
		if((this.state.branchNumber !== null && this.state.branchNumber !== 0) && (this.state.customerNumber !== null && this.state.customerNumber !== 0 && !Number.isNaN(this.state.customerNumber))){
			this.props.registrationStore.checkAccountNumber(this.state.branchNumber, this.state.customerNumber)
			.then((res:any) => {
				this.state.toastMsg = ""
				if (res.success) {
						this.setState({ isDisabled: false, showAccountMsg: false });
				} else {
					this.setState({ showAccountMsg: true });
				}
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
		}
	}
	onFocus = (e: any) => {
		// const newVal = parseInt(e.target.value)
		// e.target.open()
	}
	
	render() {
		// var parse = require('html-react-parser');
		return (
			<IonPage className="registration">
				{this.state.isLoading &&
					<div className='absolute' style={{position: "absolute", width: "100vw", height: "100vh", display: "flex", justifyContent: "center", zIndex: 9999, backgroundColor: "#00000033"}}><LoadingInside /></div>
				}
				
				<IonToolbar mode="md" className="reg-toolbar">
					<IonButtons slot="start">
						{!this.props.appStore.isWeb && <IonButton onClick={() => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>}
					</IonButtons>
					<IonTitle className="reg-title">Registration</IonTitle>
				</IonToolbar>
				<IonContent className="ion-padding">

					<IonItem lines="none">
						{/* <IonImg className="gftg-logo" src={"./assets/r3-icons/G4G_Logo_FINAL_White.svg"} /> */}
						<IonImg className="gftg-logo" src={"./assets/img/G4G_Logo_FINAL_White.png"} />
					</IonItem>

					{this.state.isSuccess ?
						<>
							<IonTitle className="reg-title">Thank You for Registering!</IonTitle>
							<IonTitle className="reg-title">We will contact you as soon as we have approved your registration.</IonTitle>
							<IonButton className="form-button" href="/">Home</IonButton>
						</>
						:
						<form onSubmit={e => this.handleSubmit(e)} action="post">
							<div className="registration-fields">
								<IonList className="promotion-list">
									<div>
										<IonLabel className="input-label-color">Email *</IonLabel>
										<IonItem>
											<IonInput type="email" name="email" onIonChange={this.onChange} required></IonInput>

											{/* <IonInput type="email" onIonChange={(e) => { this.setState({ password: (e.target as HTMLInputElement).value }); }} required></IonInput> */}
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">Password *</IonLabel>
										<IonItem>
											<IonInput type="password" name="password" onIonChange={this.onChange} required></IonInput>
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">Confirm Password *</IonLabel>
										<IonItem>
											<IonInput type="password" name="confirmPassword" onIonChange={this.onChange} required></IonInput>
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">First Name *</IonLabel>
										<IonItem>
											<IonInput name="firstName" onIonChange={this.onChange} required></IonInput>
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">Last Name *</IonLabel>
										<IonItem>
											<IonInput name="lastName" onIonChange={this.onChange} required></IonInput>
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">Phone *</IonLabel>
										<IonItem>
											<IonInput name="phone" onIonChange={this.onChange} ></IonInput>
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">Address *</IonLabel>
										<IonItem>
											<IonLabel position="stacked"></IonLabel>
											<IonInput placeholder="Addess" name="address1" onIonChange={this.onChange} required></IonInput>
											<IonInput placeholder="Addess 2" name="address2" onIonChange={this.onChange}></IonInput>
											<IonInput placeholder="City" name="city" onIonChange={this.onChange} required></IonInput>
											<IonSelect interfaceOptions={customAlertOptions}
												placeholder="Select State" name="stateId"
												onFocus={this.onFocus}
												onIonChange={this.onSelectChange} >
												{this.props.registrationStore.registration.States.map(dd => (
													<IonSelectOption key={dd.Id} value={dd}>
														{dd.Text}
													</IonSelectOption>
												))}
											</IonSelect>
											{/* <IonSelect compareWith={compareWith} value={selectedUsers} multiple onIonChange={e => setSelectedUsers(e.detail.value)}> */}
											<IonInput placeholder="ZipCode" name="zipCode" onIonChange={this.onChange} required></IonInput>
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">Industry *</IonLabel>
										<IonItem>
											<IonLabel className="input-label-color">Select Industry</IonLabel>
											<IonSelect placeholder="Select Industry" name="industryId"
												onFocus={this.onFocus}
												onIonChange={this.onSelectChange}  >
												{this.props.registrationStore.registration.Industries.map(dd => (
													<IonSelectOption key={dd.Id} value={dd}>
														{dd.Text}
													</IonSelectOption>
												))}
											</IonSelect>
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">Branch *</IonLabel>
										<IonItem>
											<IonLabel className="input-label-color">Select Branch</IonLabel>
											<IonSelect name="branchNumber"
												onFocus={this.onFocus}
												onIonChange={this.onSelectChange} >
												{this.props.registrationStore.registration.Branches.map(dd => (
													<IonSelectOption key={dd.Id} value={dd}>
														{dd.Text}
													</IonSelectOption>
												))}
											</IonSelect>
										</IonItem>
									</div>
									<div>
										<IonLabel className="input-label-color">
											Account Number
										</IonLabel>
										<IonItem>
											<IonInput type="number" name="customerNumber" onIonChange={this.onChangeNumber} ></IonInput>
										</IonItem>
											{this.state.showAccountMsg &&
											<span>
												* The Branch does not match this Account Number.
											</span>
											}
									</div>
								</IonList>
							</div>
							<IonModal isOpen={this.state.isTermsOpen} cssClass="terms-modal" backdropDismiss={false} >
								<div id="container-tou"></div>
								<IonButton onClick={() => this.setShowTerms(false)}>Close</IonButton>
							</IonModal>
							<IonModal isOpen={this.state.isPolicyOpen} cssClass="terms-modal" backdropDismiss={false} >
								<div id="container-pp"></div>
								<div dangerouslySetInnerHTML={{ __html: this.state.pp }} />
								{
									// ReactDOMServer.renderToNodeStream(this.state.pp)
								}
								<IonButton onClick={() => this.setShowPolicy(false)}>Close</IonButton>
							</IonModal>
							<IonModal isOpen={this.state.isSurveyOpen} cssClass="terms-modal" backdropDismiss={false} >
								<p>This is SURVEY content</p>
								<p>
									Terms and conditions(DUMMY):<br /><br />

									The Intellectual Property disclosure will inform users that the contents, logo and other visual media you created is your property and is protected by copyright laws.
									A Termination clause will inform that users' accounts on your website and mobile app or users' access to your website and mobile (if users can't have an account with you) can be terminated in case of abuses or at your sole discretion.
									A Governing Law will inform users which laws govern the agreement. This should the country in which your company is headquartered or the country from which you operate your website and mobile app.
									A Links To Other Web Sites clause will inform users that you are not responsible for any third party websites that you link to. This kind of clause will generally inform users that they are responsible for reading and agreeing (or disagreeing) with the Terms and Conditions or Privacy Policies of these third parties.
									If your website or mobile app allows users to create content and make that content public to other users, a Content section will inform users that they own the rights to the content they have created. The "Content" clause usually mentions that users must give you (the website or mobile app developer) a license so that you can share this content on your website/mobile app and to make it available to other users.

									Because the content created by users is public to other users, a DMCA notice clause (or Copyright Infringement ) section is helpful to inform users and copyright authors that, if any content is found to be a copyright infringement, you will respond to any DMCA takedown notices received and you will take down the content.

									A Limit What Users Can Do clause can inform users that by agreeing to use your service, they're also agreeing to not do certain things. This can be part of a very long and thorough list in your Terms and Conditions agreements so as to encompass the most amount of negative uses.
								</p>
								<IonButton onClick={() => this.setShowSurvey(false)}>Close</IonButton>
							</IonModal>
							{/* <IonGrid>
								<IonRow>
									<IonCol size="2"><IonItem lines="none"><IonCheckbox className="registration-checkbox" color="secondary" checked slot="start"></IonCheckbox></IonItem></IonCol>
									<IonCol><IonLabel>I accept the Terms of Use &amp; Privacy Policy</IonLabel></IonCol>
								</IonRow>
							</IonGrid> */}
							<div className="registration-checkbox">
								<IonItem class="checkbox-width" lines="none">
									<IonCheckbox onIonChange={e => this.setChecked(e.detail.checked)} ></IonCheckbox>
								</IonItem>
								<IonItem lines="none" className="registration-tos-text">
									<p>I accept the &nbsp;										
										{!isPlatform("desktop") && !isPlatform("mobileweb") && (isPlatform("android") || isPlatform("ios")) ?
											<IonLabel class="link-in-browser" onClick={e => this.tosLinkInBrowser()}>Terms of Use</IonLabel>
										:
											<Link to="/tou">Terms of Use</Link>
										}
										&nbsp; &amp; &nbsp;
										{!isPlatform("desktop") && !isPlatform("mobileweb") && (isPlatform("android") || isPlatform("ios"))  ?
											<IonLabel class="link-in-browser"  onClick={e => this.ppLinkInBrowser()}>Privacy Policy</IonLabel>
										:
											<Link to="/pp">Privacy Policy</Link>
										}
									</p>
								</IonItem>
							</div>
							<IonButton className="form-button" type="submit" disabled={this.state.isDisabled}>Register</IonButton>
							{/* {!this.props.appStore.isWeb ?
								<IonList className="registration-vendors">
									{this.props.registrationStore.registration.GoldVendors.map(img => (
										<IonImg className="vendorlogo" key={img.Id} alt={img.Name} src={ENV.REACT_APP_IMAGE_URL  + img.ImageId + "/Size/160"}></IonImg>
									))}

									{this.props.registrationStore.registration.SilverVendors.map(img => (
										<IonImg className="vendorlogo" key={img.Id} alt={img.Name} src={ENV.REACT_APP_IMAGE_URL  + img.ImageId + "/Size/160"}></IonImg>
									))}

									{this.props.registrationStore.registration.BronzeVendors.map(img => (
										<IonImg className="vendorlogo" key={img.Id} alt={img.Name} src={ENV.REACT_APP_IMAGE_URL + img.ImageId + "/Size/160"}></IonImg>
									))}
								</IonList>
								:
								<IonList className="registration-vendors">
									<IonLabel className="input-label-color vendors gold">Gold Vendors</IonLabel>
									<IonItem lines="none">
										{this.props.registrationStore.registration.GoldVendors.map(img => (
											<IonImg className="vendorlogo" key={img.Id} alt={img.Name} src={ENV.REACT_APP_IMAGE_URL  + img.ImageId + "/Size/160"}></IonImg>
										))}
									</IonItem>
									<IonLabel className="input-label-color vendors silver">Silver Vendors</IonLabel>
									<IonItem lines="none">
										{this.props.registrationStore.registration.SilverVendors.map(img => (
											<IonImg className="vendorlogo" key={img.Id} alt={img.Name} src={ENV.REACT_APP_IMAGE_URL  + img.ImageId + "/Size/160"}></IonImg>
										))}
									</IonItem>
									<IonLabel className="input-label-color vendors bronce">Bronze Vendors</IonLabel>
									<IonItem lines="none">
										{this.props.registrationStore.registration.BronzeVendors.map(img => (
											<IonImg className="vendorlogo" key={img.Id} alt={img.Name} src={ENV.REACT_APP_IMAGE_URL + img.ImageId + "/Size/160"}></IonImg>
										))}
									</IonItem>
								</IonList>
							} */}
						</form>
					}

					{/* https://stg.api.r3goforthegold.com/v2/Images/18894/Size/160 */}
					<IonToast
						isOpen={this.state.showToast}
						onDidDismiss={() => this.setShowToast(false, "")}
						message={this.state.toastMsg}
						position="top"
						color="danger"
						cssClass="toast-font-size"
						duration={2000}
						showCloseButton={false}
					/>
				</IonContent>
			</IonPage>
		);
	}
};

// export default Registration;

export default withIonLifeCycle(Registration);
