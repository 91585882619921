import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonGrid,
	IonRow,
	IonButtons,
	IonSlides,
	IonSlide,
	IonButton,
	withIonLifeCycle,
	IonIcon,
	IonLabel,
	IonCol,
	IonRouterLink,
	IonText,
} from "@ionic/react";
import React from "react";
import BarElement, { BarConfigData, BarSalesData } from "../components/BarElement";
import Loading from "../components/Loading";
import { RouteComponentProps } from "react-router";
import AuthenticationStore from "../mobx/AuthenticationStore";
import SalesDataStore from "../mobx/SalesDataStore";
import CustomersStore from "../mobx/CustomersStore";
import { observer } from "mobx-react";
import { arrowBack } from "ionicons/icons";
import { PATH_LOGIN, PATH_CUSTOMERS, PATH_ABOUT } from "../common/Constants";
import { getformatNumber } from "../common/utils";

import "../theme/pages/currentStatus.css";
import AppStore from "../mobx/AppStore";
import YearlyGrowthBar from "../components/YearlyGrowthBar";
import ProgressBar from "../components/progressBar/ProgressBar";

const BAR_DATA_LIST: Array<BarConfigData> = [
	{
		barNumber: 0,
		name: "Sales",
		color: "rgba(85, 185, 255, 1)",
		prefix: "$",
	},
	{
		barNumber: 1,
		name: "Points",
		color: "rgba(0, 145, 255, 1)",
	},
	{
		barNumber: 2,
		name: "Growth",
		color: "rgba(0, 74, 130, 1)",
		suffix: "%",
	},
];

const BAR_DATA_LIST_BONUS: Array<BarConfigData> = [
	{
		barNumber: 0,
		name: "Sales",
		color: "#d1a573",
		prefix: "$",
	},
	{
		barNumber: 1,
		name: "Points",
		color: "#cd8738",
	},
	{
		barNumber: 2,
		name: "Growth",
		color: "#9b5f1b",
		suffix: "%",
	},
];

type MatchParams = {
	obfuscatedIds?: string;
};

interface Props extends RouteComponentProps<MatchParams> {
	authenticationStore: AuthenticationStore;
	customersStore: CustomersStore;
	salesDataStore: SalesDataStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean;
	isLoading: boolean;
	selectedBarData: BarConfigData | null;
	isBonusTrip: boolean;
};

@observer
class CurrentStatus extends React.Component<Props, State> {
	private get barDataList() {
		if (this.state.isBonusTrip) {
			return BAR_DATA_LIST_BONUS;
		} else {
			return BAR_DATA_LIST;
		}
	}

	private get barSalesDataList() {
		if (this.state.isBonusTrip) {
			return this.props.salesDataStore.barBonusSalesDataList;
		} else {
			return this.props.salesDataStore.barSalesDataList;
		}
	}

	private get currentSales() {
		return this.props.salesDataStore.salesData?.ytdSales ?? 0;
	}

	private get bonusTripSalesGoal() {
		return this.props.salesDataStore.bonusTripDetails?.salesGoal ?? 0;
	}

	private get hasBonusTrip() {
		return (
			this.props.salesDataStore.bonusTripDetails !== null &&
			this.props.salesDataStore.bonusTripDetails.salesGoal !== 0
		);
	}

	private get hasSalesReachedGoal() {
		if (!this.hasBonusTrip) {
			return false;
		}
		return this.currentSales >= this.bonusTripSalesGoal;
	}

	state: State = {
		isHidden: true,
		isLoading: true,
		selectedBarData: null,
		isBonusTrip: false,
	};

	ionViewDidEnter() {
		this.props.authenticationStore
			.isValid()
			.then((isValid) => {
				if (isValid) {
					if (this.props.authenticationStore.isAdmin()) {
						if (this.props.match.params.obfuscatedIds !== undefined) {
							this.setState({ isHidden: false });
							this.props.customersStore.getCurrentCustomer(this.props.match.params.obfuscatedIds);
							this.props.salesDataStore.fetch(this.props.match.params.obfuscatedIds).finally(() => {
								this.setState({ isLoading: false });
							});
						} else {
							this.props.history.replace(PATH_CUSTOMERS);
						}
					} else {
						this.setState({ isHidden: false });
						this.props.customersStore.getCurrentCustomer();
						this.props.salesDataStore.fetch().finally(() => {
							this.setState({ isLoading: false });
						});
					}
				} else {
					this.props.history.replace(PATH_LOGIN);
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
	}

	setSelectedBarData = (selectedBarData: BarConfigData | null) => {
		this.setState({ selectedBarData: selectedBarData });
	};

	getBar = (barData: BarConfigData, barSalesData: BarSalesData, isClickable: boolean) => {
		return (
			<BarElement
				key={barData.barNumber}
				barData={barData}
				barSalesData={barSalesData}
				setSelectedBarData={this.setSelectedBarData}
				isClickable={isClickable}
				hasNoData={this.props.salesDataStore.hasNoData}
			></BarElement>
		);
	};

	getBarSideData = (barData: BarConfigData, barSalesData: BarSalesData, prefix: string) => {
		return (
			<IonGrid className="bar-side-data">
				<IonRow>Minimum Threshold: </IonRow>
				<IonRow>
					{prefix}
					{getformatNumber(barSalesData.goals[0], 0)}
				</IonRow>
				<IonRow>Projected Sales: </IonRow>
				<IonRow>
					{prefix}
					{getformatNumber(barSalesData.projected, 0)}
				</IonRow>
				<IonRow>% of goal: </IonRow>
				<IonRow>{getformatNumber((barSalesData.value / barSalesData.goals[0]) * 100, 2)}%</IonRow>
				<IonRow>Current {barData.name}: </IonRow>
				<IonRow>
					{prefix}
					{getformatNumber(barSalesData.value, 0)}
				</IonRow>
			</IonGrid>
		);
	};

	getBarSideDataLess = (barData: BarConfigData, barSalesData: BarSalesData, prefix: string) => {
		if (this.props.salesDataStore.hasNoData) {
			return (
				<IonGrid className="bar-side-data">
					<IonRow>Current {barData.name}: </IonRow>
					<IonRow>
						{prefix}
						{getformatNumber(barSalesData.value, 0)}
					</IonRow>
				</IonGrid>
			);
		}
		return (
			<IonGrid className="bar-side-data">
				<IonRow>Minimum Threshold: </IonRow>
				<IonRow>
					{prefix}
					{getformatNumber(barSalesData.goals[0], 0)}
				</IonRow>
				<IonRow>Current {barData.name}: </IonRow>
				<IonRow>
					{prefix}
					{getformatNumber(barSalesData.value, 0)}
				</IonRow>
			</IonGrid>
		);
	};

	render() {
		return (
			<IonPage className="current-status">
				{/* HEADER */}
				{!this.props.appStore.isWeb ? (
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								{this.state.selectedBarData == null ? (
									<IonButtons slot="start">
										<IonButton
											onClick={() => {
												this.props.history.goBack();
											}}
										>
											<IonIcon icon={arrowBack} />
										</IonButton>
									</IonButtons>
								) : (
									<IonButtons slot="start">
										<IonButton onClick={() => this.setSelectedBarData(null)}>
											<IonIcon icon={arrowBack} />
										</IonButton>
									</IonButtons>
								)}
								<IonTitle className="toolbar-title">Current Status</IonTitle>
							</IonToolbar>
							<div className="curve-static"></div>
						</IonHeader>
					</>
				) : (
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<>
									{this.state.selectedBarData == null ? (
										<></>
									) : (
										<IonButtons slot="start">
											<IonButton onClick={() => this.setSelectedBarData(null)}>
												<IonIcon icon={arrowBack} />
											</IonButton>
										</IonButtons>
									)}
									<IonTitle className="toolbar-title">Current Status</IonTitle>
								</>
							</IonToolbar>
						</IonHeader>
					</>
				)}
				{/* CONTENT */}
				{this.state.isHidden ? null : (
					<>
						<IonContent scrollY={false}>
							<div className="flex-wrapper">
								<IonGrid className="custom-width">
									<IonRow>
										<IonCol className="name">
											{this.props.customersStore.currentCustomer?.name}
										</IonCol>
									</IonRow>
									{this.props.customersStore.currentCustomer?.branchNumber && (
										<IonRow>
											<IonCol>
												<IonLabel className="name">
													Branch #{this.props.customersStore.currentCustomer?.branchNumber}
												</IonLabel>
											</IonCol>
										</IonRow>
									)}
								</IonGrid>
								{this.state.selectedBarData == null ? (
									<IonGrid className="bar-grid">
										<IonRow className="bar-row">
											{this.barSalesDataList.map((it: BarSalesData, i: number) => {
												return this.getBar(this.barDataList[i], it, true);
											})}
										</IonRow>
										{this.props.salesDataStore.salesData?.yoyPercentGrowth !== undefined && (
											<IonRow className="bar-row">
												<YearlyGrowthBar
													YearOverYearGrowth={this.props.salesDataStore.salesData?.yoyPercentGrowth}
												/>
											</IonRow>
										)}
									</IonGrid>
								) : (
									<div className="forheight">
										<IonSlides
											className="forheight bar-slides"
											pager={true}
											options={{ initialSlide: this.state.selectedBarData.barNumber }}
										>
											<IonSlide>
												{this.getBar(this.barDataList[0], this.barSalesDataList[0], false)}
												{this.getBarSideDataLess(
													this.barDataList[0],
													this.barSalesDataList[0],
													"$",
												)}
											</IonSlide>
											<IonSlide>
												{this.getBar(this.barDataList[1], this.barSalesDataList[1], false)}
												{this.getBarSideDataLess(this.barDataList[1], this.barSalesDataList[1], "")}
											</IonSlide>
											<IonSlide>
												{this.getBar(this.barDataList[2], this.barSalesDataList[2], false)}
												{this.getBarSideDataLess(this.barDataList[2], this.barSalesDataList[2], "%")}
											</IonSlide>
										</IonSlides>
									</div>
								)}
								{this.state.selectedBarData == null && this.hasBonusTrip && (
									<IonGrid className="bottom">
										{this.hasSalesReachedGoal ? (
											<IonRow style={{ width: "100%", display: "flex", flexDirection: "column", gap: "8px" }}>
												<IonText style={{ width: "100%" }}>
													Bonus Trip Opportunity
													<IonRouterLink routerLink={PATH_ABOUT}> Read More</IonRouterLink>
												</IonText>
												<IonButton
													fill={this.state.isBonusTrip ? "outline" : "solid"}
													className="bonus-trip-button"
													onClick={() => this.setState({ isBonusTrip: !this.state.isBonusTrip })}
												>
													<IonLabel>
														{this.state.isBonusTrip ? "View Regular Trip" : "View Bonus Trip"}
													</IonLabel>
												</IonButton>
											</IonRow>
										) : (
											<IonRow style={{ width: "100%", display: "flex", flexDirection: "column", gap: "8px" }}>
												<IonText style={{ width: "100%" }}>
													Bonus Trip Opportunity
													<IonRouterLink routerLink={PATH_ABOUT}> Read More</IonRouterLink>
												</IonText>
												<ProgressBar
													value={this.currentSales}
													target={this.bonusTripSalesGoal}
													prefix="$"
												/>
											</IonRow>
										)}
									</IonGrid>
								)}
							</div>
						</IonContent>
					</>
				)}
				{this.state.isLoading && <Loading />}
			</IonPage>
		);
	}
}

export default withIonLifeCycle(CurrentStatus);
