import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import React from 'react';
import CustomerModel from '../models/CustomerModel';
import { PATH_CURRENT_STATUS } from '../common/Constants';
import { arrowForward } from 'ionicons/icons';

interface Props {
	customer: CustomerModel;
}

class InboxItem extends React.Component<Props> {

	createObfuscatedIds = (): string => {
		const ids = {
			customerId: this.props.customer.number,
			branchId: this.props.customer.branchNumber
		};

		return btoa(JSON.stringify(ids));
	}

	render() {
		return (
			<IonItem detail={false} className="item-customers custom-width" lines="none" routerLink={PATH_CURRENT_STATUS + "/" + this.createObfuscatedIds()}>
				<IonLabel class="ion-text-wrap">
					{this.props.customer.name} <br/> <span className="text-branch"> Branch # {this.props.customer.branchNumber} </span>
				</IonLabel>
				<IonIcon icon={arrowForward}/>
			</IonItem>
		);
	}
};

export default InboxItem;