import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, withIonLifeCycle, IonContent, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import OtherContentStore from '../mobx/OtherContentStore';
/* Custom CSS */
import '../theme/pages/about.css';
import { arrowBack } from 'ionicons/icons';
import Loading from '../components/Loading';
import AppStore from '../mobx/AppStore';
// import ReactDOM from 'react-dom';

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	otherContentStore: OtherContentStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean,
	isError: boolean
}
class PP extends React.Component<Props, State> {
	state: State = {
		isHidden: true,
		isLoading: true,
		isError: false
	}
	
	ionViewDidEnter() {
		this.props.otherContentStore.fetchPP()
			.then((doc:any) => {
				this.setState({ isHidden: false, isLoading: false });
				document.getElementById('container2')?.append(doc);
			})
			.catch((e) => {
				this.setState({ isHidden: false, isLoading: false, isError: true });
			});
	}

	render() {
		const newElement = document.createElement('span');
		newElement.innerText = 'portal element';
		return (

			<IonPage className="about">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar className="header-toolbar">
								<IonButtons slot="start">
									<IonButton onClick={ () => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								<IonTitle className="toolbar-title">Privacy Policy</IonTitle>
							</IonToolbar>
							<div className="curve-static"></div>
						</IonHeader>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='header-toolbar'>
								<>
									<IonButton onClick={ () => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>
									<IonTitle  className="toolbar-title" >Privacy Policy</IonTitle>
								</> 
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
				this.state.isError ?
				<IonContent id="test">
						<IonItem className="message-top" lines="none">
							<IonLabel className="terms-error">
								There was an error trying to load the Privacy Policy.
							</IonLabel>
						</IonItem>
						<IonItem className="message-top" lines="none">
							<IonLabel className="terms-alternative">
								Please read the Privacy Policy <a href="http://bunzlnalegal.com/privacypolicy" target="_blank">Here</a>
							</IonLabel>
						</IonItem>
						</IonContent>:
					<>
						<IonContent id="test">
							<IonItem className="message-top" lines="none">
								<IonLabel className="subject-label">
								</IonLabel>
							</IonItem>
							<IonItem className="message-body">
								<div id="container2"></div>
							</IonItem>
						</IonContent>
					</>}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(PP);