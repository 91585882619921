import { IonContent, IonPage, IonToolbar, IonTitle, IonButtons, IonItem, IonLabel, IonInput, IonButton, IonIcon, IonImg, IonFooter } from '@ionic/react';
import React, {FormEvent } from 'react';
import AuthenticationStore from '../mobx/AuthenticationStore';
import ResetPasswordModel from '../models/ResetPasswordModel';
import { RouteComponentProps } from 'react-router';
import { arrowBack } from 'ionicons/icons';
import Copyright from '../components/Copyright';
/* Custom CSS */
import '../theme/pages/resetPassword.css';
import AppStore from '../mobx/AppStore';

interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	appStore: AppStore;
}

type State = {
	isLoading: boolean,
	sendReset: boolean,
	email: string
}

class ResetPassword extends React.Component<Props, State> {

	state: State = {
		isLoading: false,
		sendReset: false,
		email: ""
	}

	// register = () => {
	// 	this.setState({ isLoading: true });
	// 	this.props.authenticationStore.resetPassword(new ResetPasswordModel())
	// 		.finally(() => {
	// 			this.setState({ isLoading: false });
	// 		});
	// }
	async handleSubmit(e: FormEvent) {
		e.preventDefault();	
		try {
			this.setState({ isLoading: true });
			const newResetPassword= new ResetPasswordModel();
			newResetPassword.email = this.state.email;
			this.props.authenticationStore.resetPassword(newResetPassword)
			.finally(() => {
				this.setState({ isLoading: false, sendReset: true });
			});
		  // ...
		} catch (e) {
		  console.error(e);
		}
	}
	onChange = (e:any) =>{
		//input requier a name
		// @ts-ignore
		this.setState({[e.target.name]: e.target.value});
	}
	render() {
		return (
			<IonPage className="reset-password">
				<IonToolbar className="reset-toolbar">
					<IonButtons slot="start">
						{!this.props.appStore.isWeb && <IonButton onClick={ () => { this.props.history.goBack(); }}><IonIcon icon={arrowBack} /></IonButton>}
					</IonButtons>
					<IonTitle className="reset-title">Reset Password</IonTitle>
				</IonToolbar>
				<IonContent className="ion-padding">
					{!this.state.sendReset ? 
						<form onSubmit={e => this.handleSubmit(e)} action="post"><IonItem lines="none">
							{/* <IonImg className="gftg-logo" src={"./assets/r3-icons/G4G_Logo_FINAL_White.svg"} /> */}
							<IonImg className="gftg-logo" src={"./assets/img/G4G_Logo_FINAL_White.png"} />
							</IonItem>
							<IonLabel className="dont-worry"> DON'T WORRY </IonLabel><br />
							<IonLabel className="get-started"> We are here to help you. Just enter the email address you used when joined and we'll send you a new one. </IonLabel>
							<IonItem className="input-item">
								<IonInput placeholder="Email" name="email" onIonChange={this.onChange} required></IonInput>
							</IonItem>
							<IonButton className="form-button" type="submit">Reset Password</IonButton>
							<IonLabel className="email-send">An email will be sent to the above address with a link to change your password</IonLabel>
						</form>
						:
						<>
							<IonLabel className="email-send">Your request is being processed. An email will be sent to you with further instructions.</IonLabel>
							<IonButton className="form-button" href="/">Back</IonButton>
						</>
					}
				</IonContent>
				<IonFooter className="ion-no-border footer-copyright">
					<IonToolbar className="footer-copyright-tool">
						<Copyright />
					</IonToolbar>
				</IonFooter>
			</IonPage>
		);
	}
};

export default ResetPassword;