import { observable, computed, action } from "mobx";


export default class AppStore {

    @observable private _isWeb: boolean = false;
    @computed public get isWeb() {
        return this._isWeb;
    }
     
    // constructor(private ga: GoogleAnalytics) { }

    @action onWindowResize() {
        if (window.innerWidth <= 768 ) {
			this._isWeb= false;
		} else {
			this._isWeb= true;
		}
    }  
}