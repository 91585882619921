import { observable, computed, action } from "mobx";
import RegistrationModel from "../models/RegistrationModel";
import Axios, { AxiosResponse } from 'axios';
// import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";

export default class RegistrationStore {
    private _appRegistryStore: AppRegistryStore;
    // private _authenticationStore: AuthenticationStore;

    @observable private _registration: RegistrationModel = {
        Password: "",
        GoldVendors: [],
        SilverVendors: [],
        BronzeVendors: [],
        ConfirmPassword: "",
        Email: "",
        FirstName: "",
        LastName: "",
        Address1: "",
        Address2: "",
        City: "",
        StateId:0,
        States: [],
        ZipCode: "",
        Phone: "",
        IndustryId:0,
        Industries: [],
        BranchNumber:0,
        Branches: [],
        CustomerNumber:0,
        AgreedToTermsOfUse:false
    }
    constructor(appRegistryStore: AppRegistryStore) {
        this._appRegistryStore = appRegistryStore;
        // this._authenticationStore = authenticationStore;
    }

    @computed get registration() {
        return this._registration;
    }

    @action fetch() {
        // this.RegistrationModel = [];
        const url = this._appRegistryStore.mobileApiUrl + "/v3/Registration/";
        // return Axios.get<any, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
        return Axios.get<any, AxiosResponse<any>>(url)
            .then((res: AxiosResponse<any>) => {                
                // res.data.forEach((item: any) => {
                this._registration = res.data;
                // });
            });
    }
    checkAccountNumber(branch:number, account: number){
        const url = this._appRegistryStore.mobileApiUrl + `/v3/Registration/ValidateCustomerNumber/${branch}/${account}`;
        return Axios.get<any, AxiosResponse<any>>(url)
            .then(async (res: AxiosResponse<any>) => {
                console.log(res, res.data)
                if(res.data)
                    return {success:true, msg: ""};
                return {success:false, msg: ["The Branch does not match this Account Number."]};
            })
            .catch((err) => {
                console.log('catch register', err);
                if (err.response.status === 400) {
                    return {success:false, msg: JSON.parse(err.response.data.Message)};
                }
                if (err.response.status === 500) {
                    return {success:false, msg: [err.response.data.ExceptionMessage]};
                }
                return {success:false, msg: err.message};
                // if (err.response === undefined) {
                //     return this._cleanUp();
                // } else {
                //     return {
                //         isSuccessful: false,
                //         message: err.response.Message
                //     } as LoginResult;
                // }
            });
    }
    register() {
        // v3/Registration
        const url = this._appRegistryStore.mobileApiUrl + "/v3/Registration";
        return Axios.post<any, AxiosResponse<any>>(url, this._registration)
            .then(async (res: AxiosResponse<any>) => {
                return {success:true, msg: ""};
            })
            .catch((err) => {
                console.log('catch register', err);
                if (err.response.status === 400) {
                    return {success:false, msg: JSON.parse(err.response.data.Message)};
                }
                if (err.response.status === 500) {
                    return {success:false, msg: [err.response.data.ExceptionMessage]};
                }
                return {success:false, msg: err.message};
                // if (err.response === undefined) {
                //     return this._cleanUp();
                // } else {
                //     return {
                //         isSuccessful: false,
                //         message: err.response.Message
                //     } as LoginResult;
                // }
            });
    }
}