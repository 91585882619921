import { IonLabel } from "@ionic/react";
import React from "react";



class Copyright extends React.Component {
	getYear() {
		return new Date().getFullYear();
	}
	
	render() {
		return (
            <IonLabel className="footer-text">© {this.getYear()} R3 Redistribution - v8.03.01</IonLabel>
		);
	}
};

export default Copyright;
