import { observable, computed, action } from "mobx";
import CustomerModel from "../models/CustomerModel";
import Axios, { AxiosResponse } from 'axios';
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";

export default class CustomersStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;
    private _lastSearchId = 0;
    private _count = 0;
    @observable private _customers: Array<CustomerModel> = [];
    @observable private _currentCustomer: CustomerModel | null = null;

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @computed get customers() {
        return this._customers;
    }

    @computed get currentCustomer() {
        return this._currentCustomer;
    }

    @action search(searchString: string, isDesc: boolean, count: number, shouldReset: boolean) {
        const searchId = ++this._lastSearchId;

        if (shouldReset) {
            this._customers = [];
            this._count = 0;
        }

        const url = this._appRegistryStore.mobileApiUrl + "/v3/SalesRep/Customers/Search";

        const data = {
            Name: searchString,
            Number: -1,
            Skip: this._count,
            Top: count,
            SortByName: true,
            Descending: isDesc
        }

        this._count = this._count + count;

        return Axios.post<any, AxiosResponse<any>>(url, data, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
            .then((res: AxiosResponse<any>) => {
                if (searchId !== this._lastSearchId) return -1;
                res.data.forEach((item: any) => {
                    this._customers.push({
                        parentNumber: item.ParentNumber,
                        parentBranchNumber: item.ParentBranchNumber,
                        number: item.Number,
                        branchNumber: item.BranchNumber,
                        name: item.Name,
                        salesRepCode: item.SalesRepCode,
                    });
                });

                return res.data.length;
            });
    }

    @action getCurrentCustomer(obfuscatedIds?: string) {
        this._currentCustomer = null;

        if (obfuscatedIds !== undefined && this._authenticationStore.isAdmin()) {
            const ids = JSON.parse(atob(obfuscatedIds!));
            
            const searchId = ++this._lastSearchId;

            const url = this._appRegistryStore.mobileApiUrl + "/v3/SalesRep/Customers/Search";

            const data = {
                Name: '',
                Number: ids.customerId,
                Skip: 0,
                Top: 1,
                SortByName: true,
                Descending: false
            }

            return Axios.post<any, AxiosResponse<any>>(url, data, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
                .then((res: AxiosResponse<any>) => {
                    if (searchId !== this._lastSearchId) return -1;
                    res.data.forEach((item: any) => {
                        this._currentCustomer = {
                            parentNumber: item.ParentNumber,
                            parentBranchNumber: item.ParentBranchNumber,
                            number: item.Number,
                            branchNumber: item.BranchNumber,
                            name: item.Name,
                            salesRepCode: item.SalesRepCode,
                        };
                    });

                    return res.data.length;
                });
        } else {
            this._currentCustomer = null;
        }
    }
}