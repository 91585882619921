import { observable, computed, action } from "mobx";
import ProductModel from "../models/ProductModel";
import Axios, { AxiosResponse } from 'axios';
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";
import MediaDetailModel from "../models/MediaDetailModel";

export default class ProductStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;
    @observable private _product: ProductModel | null = null;

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @computed get product() {
        return this._product;
    }

    @action fetch(id: number, screenWidth: number) {
        this._product = null;
        const url = this._appRegistryStore.mobileApiUrl + "/v3/Product/" + id + "/ImageSize/" + screenWidth;
        return Axios.get<any, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer "+this._authenticationStore.token } })
        .then((res: AxiosResponse<any>) => {
            this._product = 
            {
                id: res.data.Id,
                number: res.data.Number,
                name: res.data.Name,
                description: res.data.Description,
                points: res.data.Points,
                vendorName: res.data.VendorName,
                categoryName: res.data.CategoryName,
                manufacturerName: res.data.ManufacturerName,
                imageId: res.data.ImageId,
                isFeatured: res.data.IsFeatured,
                isDoublePoints: res.data.IsDoublePoints,
                imageBytes: res.data.ImageBytes,
            };
        });
    }

    @action downloadMedia(isZipped: boolean) {
        const itemNumber = this._product!.number;

        if (isZipped) {
            const url = this._appRegistryStore.mobileApiUrl + `/v2/Media/Zipped?itemNumbers=${itemNumber}`;

            Axios.get<any, AxiosResponse<ArrayBuffer>>(
                url,
                {
                    responseType: "arraybuffer",
                    headers: {
                        Authorization: "Bearer " + this._authenticationStore.token,
                    },
                })
            .then((res: AxiosResponse<ArrayBuffer>) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "media.zip");
                document.body.appendChild(link);
                link.click();
            });
        } else {
            const url = this._appRegistryStore.mobileApiUrl + `/v2/Media?withData=true&itemNumbers=${itemNumber}`;
            Axios.get<any, AxiosResponse<{ [key: string]: Array<MediaDetailModel> }>>(
                url,
                {
                    headers: { Authorization: "Bearer " + this._authenticationStore.token },
                })
            .then(async (res: AxiosResponse<{ [key: string]: Array<MediaDetailModel> }>) => {

                const mediaDetails = res.data[itemNumber];
                if (mediaDetails !== undefined) {
                    for (let i = 0; i < mediaDetails.length; ++i) {
                        const mediaDetail = mediaDetails[i];

                        const byteCharacters = atob(mediaDetail.Data);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);

                        const url = window.URL.createObjectURL(new Blob([byteArray]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', mediaDetail.FileName);
                        document.body.appendChild(link);
                        link.click();
                    }
                }
            });
        }
    }
}