
export const PATH_LOGIN = "/login";
export const PATH_REGISTRATION = "/registration";
export const PATH_RESET_PASSWORD = "/reset-password";
export const PATH_HOME = "/home";
export const PATH_PRODUCTS = "/products";
export const PATH_PROMOTION_PRODUCTS = "/promotion-products";
export const PATH_PRODUCT_DETAIL = PATH_PRODUCTS;
export const PATH_PROMOTIONS = "/promotions";
export const PATH_PROMOTION_DETAIL = PATH_PROMOTIONS;
export const PATH_INBOX = "/inbox";
export const PATH_INBOX_MAIL = PATH_INBOX;
export const PATH_CUSTOMERS = "/customers";
export const PATH_CURRENT_STATUS = "/current-status";
export const PATH_FEATURED_VENDORS = "/featured-vendors";
export const PATH_RANKING = "/ranking";
export const PATH_USER_ACCOUNT = "/user-account";
export const PATH_ABOUT = "/about";
export const PATH_MY_SALES_REP = "/sales-rep";
export const PATH_TOU = "/tou";
export const PATH_PP = "/pp";
export const PATH_404 = "/404-page-not-found";

export const CLIENT_APPLICATION_CODE = "Go4Gold";
export const CLIENT_APPLICATION_CODE_BNS = "BNS";
export const SENDER_ID = "406529415056";
export const GOOGLE_TRACKING_ID = "UA-50697226-3";

export const HttpStatus = {
    OK: 200
}
