import React from 'react';
import { IonBackdrop } from '@ionic/react';

class NetworkCheck extends React.Component {

    render() {
        return (
            <>
                <IonBackdrop tappable={false} visible={true} stopPropagation={true} >
                </IonBackdrop>
            </>
        );
    }
};

export default NetworkCheck;