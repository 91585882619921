import { observable, computed, action } from "mobx";
import PromotionModel from "../models/PromotionModel";
import Axios, { AxiosResponse } from 'axios';
import AuthenticationStore from "./AuthenticationStore";
import AppRegistryStore from "./AppRegistryStore";

export default class PromotionsStore {
    private _appRegistryStore: AppRegistryStore;
    private _authenticationStore: AuthenticationStore;
    @observable private _promotions: Array<PromotionModel> = [];

    constructor(appRegistryStore: AppRegistryStore, authenticationStore: AuthenticationStore) {
        this._appRegistryStore = appRegistryStore;
        this._authenticationStore = authenticationStore;
    }

    @computed get promotions() {
        return this._promotions;
    }

    @action fetch() {
        this._promotions = [];

        const url = this._appRegistryStore.mobileApiUrl + "/v2/Promotion/Type/Item";
        return Axios.get<any, AxiosResponse<any>>(url, { headers: { Authorization: "Bearer " + this._authenticationStore.token } })
            .then(async (res: AxiosResponse<any>) => {
                this._promotions = [];
                res.data.forEach((item: any) => {
                    // if(item.PromotionTypeName === "Item")
                        this._promotions.push({
                            id: item.Id,
                            name: item.Name,
                            description: item.Description,
                            startDate: item.StartDate,
                            endDate: item.EndDate,
                            promotionTypeName: item.PromotionTypeName,
                            screenType: item.ScreenType,
                            positionType: item.PositionType,
                            destinationTypeName: item.DestinationTypeName,
                            vendorName: item.VendorName,
                            imageName: item.ImageName,
                            image: item.Image,
                            altImageName: item.AltImageName,
                            altImage: item.AltImage,
                            productCount: item.ProductCount,
                        });
                });
            });
    }
}