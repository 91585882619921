import { IonItem, IonImg, IonIcon, IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import React from 'react';
import PromotionModel from '../models/PromotionModel';
import { arrowForward } from 'ionicons/icons';
import { PATH_PROMOTION_PRODUCTS } from '../common/Constants';
import { getformatDate } from '../common/utils';

interface Props {
    promotion: PromotionModel;
}

class Promotion extends React.Component<Props> {

	render() {
		return (
			<IonItem detail={false} lines="none" className="promotion-card" routerLink={PATH_PROMOTION_PRODUCTS + "?promotionId=" + this.props.promotion.id}>
                <IonGrid className="contents">
                    <IonRow className="contents">
                        <IonCol className="img-container" size="3.5">
                            <IonImg src={ this.props.promotion.image !== "" ? "data:image/jpeg;base64," + this.props.promotion.image : "./assets/img/product-no-img.PNG"} />
                        </IonCol>
                        <IonCol>
                            <IonText className="promo-name">{this.props.promotion.name}<br /></IonText>
                            <IonText className="promo-desc">{this.props.promotion.description}<br /></IonText>
                            <IonText className="promo-dates">{getformatDate(this.props.promotion.startDate)} through {getformatDate(this.props.promotion.endDate)}</IonText>
                        </IonCol>
                        <IonCol size="1">
                            <IonIcon className="card-forward" icon={arrowForward}></IonIcon>
                        </IonCol>
                    </IonRow>
                </IonGrid>
			</IonItem>
		);
	}
};

export default Promotion;