import { IonPage, IonHeader, IonToolbar, IonTitle, IonList, IonContent, IonIcon, IonButton, IonLabel, IonSearchbar, withIonLifeCycle, IonCheckbox, IonText, IonButtons, IonItem, IonInfiniteScroll, IonInfiniteScrollContent, IonImg } from '@ionic/react';
import React from 'react';
import ProductsItem from '../components/ProductsItem';
import { RouteComponentProps, Router } from 'react-router';
import AuthenticationStore from '../mobx/AuthenticationStore';
import Loading from '../components/Loading';
import LoadingInside from '../components/LoadingInside';
import ProductModel from '../models/ProductModel';
import BannerModel from "../models/BannerModel";
import ProductsStore from '../mobx/ProductsStore';
import { observer } from 'mobx-react';
/* Custom CSS */
import '../theme/pages/products.css';
import { arrowDown, arrowUp, arrowBack, funnel, close } from 'ionicons/icons';
import { PATH_LOGIN } from '../common/Constants';
import queryString from 'query-string';
import { isStringANumber } from '../common/utils';
import AppStore from '../mobx/AppStore';
import Slider from 'infinite-react-carousel';
import { PATH_PRODUCTS, PATH_PROMOTION_PRODUCTS } from '../common/Constants';


interface Props extends RouteComponentProps {
	authenticationStore: AuthenticationStore;
	productsStore: ProductsStore;
	appStore: AppStore;
}

type State = {
	isHidden: boolean,
	isLoading: boolean,
	isLoadingProducts: boolean,
	isSearching: boolean,
	searchString: string,
	isDesc: boolean,
	isFeatured: boolean,
	isFiltersOpen: boolean
	downPoint: 0,
	upPoint: 0,
	indexSlider: 0
}

type Member = {
	isMounted: boolean,
	vendorId?: number,
	promotionId?: number,
	infiniteScroll: React.RefObject<HTMLIonInfiniteScrollElement>
}

@observer
class Products extends React.Component<Props, State> {

	state: State = {
		isHidden: true,
		isLoading: true,
		isLoadingProducts: false,
		isSearching: false,
		searchString: "",
		isDesc: false,
		isFeatured: false,
		isFiltersOpen: false,
		downPoint: 0,
		upPoint: 0,
		indexSlider: 0
	}

	member: Member = {
		isMounted: false,
		infiniteScroll: React.createRef<HTMLIonInfiniteScrollElement>()
	}

	ionViewDidEnter() {
		this.member.isMounted = true;
		this.props.authenticationStore.isValid()
			.then((isValid) => {
				if (this.member.isMounted) {
					if (isValid) {
						this.setState({ isHidden: false});
	
						const query = queryString.parse(this.props.history.location.search);
						if (query.vendorId !== undefined && typeof(query.vendorId) == "string") {
							const vendorIdStr = query.vendorId as string;
							if (isStringANumber(vendorIdStr)) {
								this.member.vendorId = parseInt(vendorIdStr);
							}
						}
						else {
							this.member.vendorId = undefined
						}

						if (query.promotionId !== undefined && typeof(query.promotionId) == "string") {
							const promotionIdStr = query.promotionId as string;
							if (isStringANumber(promotionIdStr)) {
								this.member.promotionId = parseInt(promotionIdStr);
							} else {
								this.member.promotionId = undefined
							}
						} else {
							this.member.promotionId = undefined
						}
						this.search(true, true);
						this.setState({ isLoading: false });
					} else {
						this.props.history.replace(PATH_LOGIN);
					}
				}
			})
			.catch(() => {
				this.props.history.replace(PATH_LOGIN);
			});
	}

	ionViewWillLeave() {
		this.member.isMounted = false;
	}

	search = (shouldLoad: boolean, shouldReset: boolean) => {
		this.setState({isLoadingProducts: shouldLoad, isSearching: true });

		this.member.infiniteScroll.current!.disabled = false;

		if (!this.isPromotionProductsPage()){
			this.props.productsStore.getBanners();
		}
		this.props.productsStore.search(
			this.state.searchString,
			this.member.vendorId,
			this.member.promotionId,
			this.state.isFeatured,
			this.state.isDesc,
			720, // ProductsItems image width
			20,
			shouldReset)
			.then((length) => {
				if (length === -1) return;
				if (this.member.isMounted) {
					if (length > 0) {
						this.member.infiniteScroll.current!.complete();
					} else {
						this.member.infiniteScroll.current!.disabled = true;
					}
				}
				this.setState({ isLoadingProducts: false, isSearching: false });
			})
			.catch(() => {
				this.setState({ isLoadingProducts: false, isSearching: false });
			});
	}

	onSearchInput = (input: string) => {
		this.setState({searchString: input}, () => { this.search(true, true); });
	}

	setIsFeatured = (isFeatured: boolean) => {
		this.setState({isFeatured: isFeatured}, () => { this.search(true, true); });
	}

	toggleIsDesc = () => {
		this.setState({isDesc: !this.state.isDesc}, () => { this.search(true, true); });
	}
	goToPromotion = (url) => {
		const difference = Math.abs(this.state.downPoint - this.state.upPoint);
		if(difference < 15){
			console.log('click', this.props.productsStore.banners[this.state.indexSlider].URL)
			const newUrl = this.props.productsStore.banners[this.state.indexSlider].URL;
			if(newUrl != null && newUrl.includes("promotionId=")){
				this.props.history.push(PATH_PROMOTION_PRODUCTS + "?" + newUrl);
			}
			if(newUrl != null && newUrl.includes("http")){
				window.open(newUrl);
			}
		}
		this.state.upPoint = 0;
	}
	onPressDown = (e) => {
		this.state.downPoint = e.clientX;
	}
	
	onPressUp = (e) => {
		this.state.upPoint = e.clientX;
	}
	changeIndexSlider = (a,b) => {
		if(b != undefined){
			this.state.indexSlider = b;
		} else {
			this.state.indexSlider = a;
		}
	}
	changeTop = () => {
		const filterTab = document.getElementById("content-filters");
		const mainTab = document.getElementById("content-products");
		if (filterTab && mainTab){
			if (!this.state.isFiltersOpen){
				filterTab.style.setProperty("transform", "translateY(0vh)");
				mainTab.style.setProperty("transform", "translateY(calc(var(--content-height)))");
				mainTab.style.setProperty("--offset-bottom","calc(0vh - calc(var(--content-height)))", "important");
				this.setState({isFiltersOpen: true});
			} else {
				filterTab.style.setProperty("transform", "translateY(calc( 5vh - var(--content-height)))");
				mainTab.style.setProperty("transform", "translateY(5vh)");
				mainTab.style.setProperty("--offset-bottom","-5vh", "important");
				this.setState({isFiltersOpen: false});
			}
		}
	}
	isPromotionProductsPage = () => {
		return this.props.location.pathname.includes(PATH_PROMOTION_PRODUCTS);
	}
	render() {
		return (
			<IonPage className="products">
				{!this.props.appStore.isWeb ?
					<>
						<IonHeader>
							<IonToolbar mode="md" className='products-toolbar'>
								<IonButtons slot="start">
									<IonButton onClick={ () => { this.props.history.goBack(); } }><IonIcon icon={arrowBack} /></IonButton>
								</IonButtons>
								{(!this.state.isFiltersOpen) ? 
									<>
										<IonTitle onClick={this.changeTop} className="toolbar-title" >Products</IonTitle>
										<IonIcon className="filter-icons" onClick={this.changeTop} slot="end" icon={funnel} />
									</> 
									:
									<>	
										<IonTitle onClick={this.changeTop} className="toolbar-title" >Filter</IonTitle>
										<IonIcon className="filter-icons" onClick={this.changeTop} slot="end" icon={close} />
									</> 
								}
							</IonToolbar>
						</IonHeader>
						<IonContent className="content-filters" id="content-filters" >
							<div className="filter-area">
								<IonLabel className="sort-title">Sort By</IonLabel>
								<IonItem lines="none" className="item-checkbox">
									<IonLabel>Featured</IonLabel>
									<IonCheckbox checked={this.state.isFeatured} onIonChange={e => this.setIsFeatured(e.detail.checked)} />
								</IonItem>
								<IonItem detail={false} lines="none" className="item-direction" button={true} onClick={this.toggleIsDesc}>
									<IonLabel>Alphabetically</IonLabel>
									<IonButton className="sort-direction" onClick={(e) => { this.toggleIsDesc(); e.stopPropagation(); }}><IonIcon icon={this.state.isDesc ? arrowDown : arrowUp} /></IonButton>
								</IonItem>
								<br />
								<IonLabel className="sort-title">Search</IonLabel>
								<IonSearchbar className="product-search" placeholder="Product Name, Vendor, Category" onInput={e => this.onSearchInput(e.currentTarget.value!)}></IonSearchbar>
							</div>
							<div className="curve"></div>
						</IonContent>
					</>
				:
					<>
						<IonHeader>
							<IonToolbar className='products-toolbar'>
									<>
										<IonTitle onClick={this.changeTop} className="toolbar-title" >Products</IonTitle>
									</> 
									<>	
										<IonItem lines="none" className="item-checkbox">
											<IonLabel>Filter Featured</IonLabel>
											<IonCheckbox checked={this.state.isFeatured} onIonChange={e => this.setIsFeatured(e.detail.checked)} />
										</IonItem>
										<IonItem mode="md" lines="none" className="item-direction" button={true} onClick={this.toggleIsDesc}>
											<IonLabel>Sort Alphabetically</IonLabel>
											<IonButton className="sort-direction" onClick={(e) => { this.toggleIsDesc(); e.stopPropagation(); }}><IonIcon icon={this.state.isDesc ? arrowDown : arrowUp} /></IonButton>
										</IonItem>
										<IonItem lines="none" className="item-direction item-search">
											<IonSearchbar className="product-search" placeholder="Search Product Name, Vendor, Category" onInput={e => this.onSearchInput(e.currentTarget.value!)}></IonSearchbar>
										</IonItem>
									</>
							</IonToolbar>
						</IonHeader>
					</>
				}
				{this.state.isHidden ? null :
					<>
						<IonContent className="content-products" id="content-products">
							{(!this.isPromotionProductsPage() && this.props.productsStore.banners.length !== 0) &&
								<div onMouseDown={(e) => {this.onPressDown(e)}}  onMouseUp={(e) => { this.onPressUp(e); }} onClick={(e) => { this.goToPromotion(e); e.stopPropagation(); }}>
									<Slider arrows={false} autoplay={true} autoplaySpeed={5000} duration={500} beforeChange={(a, b) => { this.changeIndexSlider(a, b);}} afterChange={(a, b) => { this.changeIndexSlider(a, b);}} >
										{this.props.productsStore.banners.map((item: BannerModel, index: number) => {
											return <div><IonImg src={item.image} ></IonImg></div>
										})}
										{/* <div>
											<IonImg src="http://stg.r3goforthegold.com/-/media/us/go-for-the-gold/Graphics/Banners/g4g-banner"></IonImg>
										</div>
										<div>
											<IonImg src="http://stg.r3goforthegold.com/-/media/us/go-for-the-gold/Graphics/Banners/g4g-banner"></IonImg>
										</div> */}
									</Slider>
								</div>
							}
							{this.state.isLoadingProducts ? <LoadingInside /> :
							<>
								{(this.state.isSearching || this.props.productsStore.products.length !== 0) ? 
								<IonList className="product-list">
									{this.props.productsStore.products.map((it: ProductModel, index: number) => {
										return <ProductsItem key={index} product={it} ></ProductsItem>
									})}
								</IonList> 
								:
								<IonText class="text-center">Search did not match any products</IonText>}

								</>
							}
							<IonInfiniteScroll ref={this.member.infiniteScroll} threshold="100px" onIonInfinite={() => {this.search(false, false)}}>
								<IonInfiniteScrollContent loadingText="Loading products..." >
								</IonInfiniteScrollContent>
							</IonInfiniteScroll>
						</IonContent>
						
						
					</>
				}
				{(this.state.isLoading) && <Loading />}
			</IonPage>
		);
	}
};

export default withIonLifeCycle(Products);
